import { useEffect, useState } from "react";
import { useDebounceValue } from "usehooks-ts";
import { GlobalState } from "../common/state/machine";
import { Input } from "@/core/components/third-party/ui/input";
import { Badge } from "@/core/components/third-party/ui/badge";

export function SearchBar() {
  const globalState = GlobalState.machine.useState();

  const [value, setValue] = useState(globalState.readonly.company_name ?? "");
  const [bounceValue] = useDebounceValue(value, 500);

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  useEffect(() => {
    globalState.mutable.setCompanyName(value);
  }, [bounceValue]);

  return (
    <div className="relative w-full" data-testid="company-search-container">
      <Input value={value} placeholder="Search for companies..." onChange={handleOnChange} />
      <Badge
        variant={globalState.readonly.companiesIsPending ? "outline" : "default"}
        className="absolute right-2 top-1/2 -translate-y-1/2"
        data-testid="company-search-results-badge"
      >
        {globalState.readonly.companiesIsPending ? (
          <div className="flex items-center gap-2" data-testid="company-search-loading-state">
            <div
              className="h-3 w-3 animate-spin rounded-full border-2 border-current border-t-transparent"
              data-testid="company-search-loading-spinner"
            />
            Loading...
          </div>
        ) : (
          `${globalState.readonly.total_count} Results`
        )}
      </Badge>
    </div>
  );
}
