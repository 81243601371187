import * as React from "react";
import { ChevronDown, CheckIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Popover, PopoverContent, PopoverTrigger } from "@/core/components/third-party/ui/popover";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandInput,
} from "@/core/components/third-party/ui/command";
import { useRouter, useSearch } from "@tanstack/react-router";

const YEAR_OPTIONS = {
  quickOptions: [
    {
      label: "Latest year: 2024",
      value: "2024",
      type: "quick",
      disabled: false,
    },
    {
      label: "Last 5 years: 2019-2023",
      value: "2019-2023",
      type: "quick",
      selected: true,
      disabled: false,
    },
    {
      label: "All years: 2015-2024",
      value: "2015-2024",
      type: "quick",
      disabled: false,
    },
  ],
  years: Array.from({ length: 10 }, (_, i) => {
    const year = 2024 - i;
    return {
      label: year.toString(),
      value: year.toString(),
      type: "year",
      disabled: false,
    };
  }),
};

export const TimePeriodSelect = React.forwardRef<HTMLButtonElement, { className?: string }>(({ className }, ref) => {
  const router = useRouter();

  const [selectedYears, setSelectedYears] = React.useState<Set<string>>(() => {
    // Find the default selected option
    const defaultOption = YEAR_OPTIONS.quickOptions.find((opt) => opt.selected);
    if (!defaultOption) return new Set();

    if (!defaultOption.value.includes("-")) {
      return new Set([defaultOption.value]);
    }

    const [startYear, endYear] = defaultOption.value.split("-");
    const years = Array.from({ length: parseInt(endYear) - parseInt(startYear) + 1 }, (_, i) =>
      (parseInt(startYear) + i).toString(),
    );
    return new Set(years);
  });
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const handleSelection = (option: (typeof YEAR_OPTIONS.years)[0] | (typeof YEAR_OPTIONS.quickOptions)[0]) => {
    if (option.type === "quick") {
      const [startYear, endYear] = option.value.split("-");
      if (endYear) {
        // Handle range selection
        const years = Array.from({ length: parseInt(endYear) - parseInt(startYear) + 1 }, (_, i) =>
          (parseInt(startYear) + i).toString(),
        );
        setSelectedYears(new Set(years));
        // Update URL with selected years
        router.navigate({
          from: "/",
          search: (prev) => ({
            ...prev,
            time_period: years,
          }),
          replace: true,
        });
      } else {
        // Handle single year quick selection
        setSelectedYears(new Set([option.value]));
        router.navigate({
          from: "/",
          search: (prev) => ({
            ...prev,
            time_period: [option.value],
          }),
          replace: true,
        });
      }
    } else {
      // Toggle individual year selection
      const newSelection = new Set(selectedYears);
      if (newSelection.has(option.value)) {
        newSelection.delete(option.value);
      } else {
        newSelection.add(option.value);
      }
      setSelectedYears(newSelection);

      // Update URL with the new selection
      const updatedYears = Array.from(newSelection);
      router.navigate({
        from: "/",
        search: (prev) => ({
          ...prev,
          time_period: updatedYears,
        }),
        replace: true,
      });
    }
  };

  const getTriggerText = () => {
    if (selectedYears.size === 0) return "Select time period";

    const years = Array.from(selectedYears)
      .map(Number)
      .sort((a, b) => a - b);
    const minYear = Math.min(...years);
    const maxYear = Math.max(...years);

    return `${minYear}-${maxYear} (${selectedYears.size} Periods)`;
  };

  const isQuickOptionSelected = (option: (typeof YEAR_OPTIONS.quickOptions)[0]) => {
    if (!option.value.includes("-")) {
      // For single year options
      return selectedYears.has(option.value) && selectedYears.size === 1;
    }

    const [startYear, endYear] = option.value.split("-");
    const expectedYears = Array.from({ length: parseInt(endYear) - parseInt(startYear) + 1 }, (_, i) =>
      (parseInt(startYear) + i).toString(),
    );

    // Check if the selection exactly matches the expected years
    return expectedYears.length === selectedYears.size && expectedYears.every((year) => selectedYears.has(year));
  };

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger
        ref={ref}
        data-testid="time-period-select-trigger"
        className={cn(
          "flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1",
          className,
        )}
      >
        <span data-testid="time-period-select-text">{getTriggerText()}</span>
        <ChevronDown className="h-4 w-4 opacity-50 shrink-0" />
      </PopoverTrigger>

      <PopoverContent className="w-[250px] p-0" align="start">
        <Command>
          <CommandInput placeholder="Search years..." data-testid="time-period-search-input" />
          <CommandList>
            <CommandGroup heading="Quick Options">
              {YEAR_OPTIONS.quickOptions.map((option) => (
                <CommandItem
                  key={option.value}
                  data-testid={`quick-option-${option.value}`}
                  onSelect={() => !option.disabled && handleSelection(option)}
                  className={cn("cursor-pointer", option.disabled && "opacity-50 cursor-not-allowed")}
                >
                  <div
                    data-testid={`quick-option-checkbox-${option.value}`}
                    className={cn(
                      "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border",
                      isQuickOptionSelected(option)
                        ? "bg-primary text-primary-foreground"
                        : "opacity-50 [&_svg]:invisible",
                      option.disabled && "bg-muted",
                    )}
                  >
                    <CheckIcon className="h-4 w-4" />
                  </div>
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>

            <CommandSeparator />

            <CommandGroup heading="Individual Years">
              {YEAR_OPTIONS.years.map((option) => (
                <CommandItem
                  key={option.value}
                  data-testid={`year-option-${option.value}`}
                  onSelect={() => !option.disabled && handleSelection(option)}
                  className={cn("cursor-pointer", option.disabled && "opacity-50 cursor-not-allowed")}
                >
                  <div
                    data-testid={`year-checkbox-${option.value}`}
                    className={cn(
                      "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border",
                      selectedYears.has(option.value)
                        ? "bg-primary text-primary-foreground"
                        : "opacity-50 [&_svg]:invisible",
                      option.disabled && "bg-muted",
                    )}
                  >
                    <CheckIcon className="h-4 w-4" />
                  </div>
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
});

TimePeriodSelect.displayName = "TimePeriodSelect";
