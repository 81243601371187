import { PageHeaderSimpleContainer } from "@/core/components/molecules/page-headers/PageHeadersSimple";
import { Separator } from "@/core/components/third-party/ui/separator";
import { SidebarTrigger } from "@/core/components/third-party/ui/sidebar";
import {
  Breadcrumb,
  BreadcrumbSeparator,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
} from "@/core/components/third-party/ui/breadcrumb";
import { StateInjector } from "./common/state/state-injector";
import { DataInjector } from "./common/state/data-injector";
import { ResultsStack } from "./results-stack/ResultsStack";
import { DesktopControlPanel } from "./control-panel/DesktopControlPanel";

export function Root() {
  return (
    <StateInjector>
      <DataInjector>
        <div className="flex" data-testid="root-container">
          <div className="px-4 pb-[30px] w-full flex flex-col gap-3" data-testid="main-content">
            <header
              className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12"
              data-testid="header"
            >
              <div className="flex items-center gap-2" data-testid="header-content">
                <SidebarTrigger className="-ml-1" />
                <Separator orientation="vertical" className="mr-2 h-4" />
                <Breadcrumb>
                  <BreadcrumbList>
                    <BreadcrumbSeparator className="hidden md:block" />
                    <BreadcrumbItem>
                      <BreadcrumbPage>Company Search</BreadcrumbPage>
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
              </div>
            </header>
            <div className="flex items-start gap-6 justify-between w-full" data-testid="content-wrapper">
              <div className="flex flex-col gap-5" data-testid="page-header">
                <PageHeaderSimpleContainer>
                  <PageHeaderSimpleContainer.TitleSection>
                    <PageHeaderSimpleContainer.Title>Company Search</PageHeaderSimpleContainer.Title>
                    <PageHeaderSimpleContainer.SupportingText>
                      Find and compare companies, add them to your peer set, and benchmark them.
                    </PageHeaderSimpleContainer.SupportingText>
                  </PageHeaderSimpleContainer.TitleSection>
                </PageHeaderSimpleContainer>
              </div>
            </div>
            <div className="w-full" data-testid="results-stack">
              <ResultsStack />
            </div>
          </div>
          <DesktopControlPanel />
        </div>
      </DataInjector>
    </StateInjector>
  );
}
