export function acronym(input: string | undefined, length: number): string {
  if (!input) return "-";
  // Split the input string into an array of words
  const words = input.split(" ");

  // Extract the first letter of each word and join them together to form the acronym
  let acronym = words.reduce((result, word) => result + word[0], "");

  // Limit the length of the acronym if necessary
  if (acronym.length > length) {
    acronym = acronym.slice(0, length);
  }

  return acronym.toUpperCase();
}

export function compactNumber(number?: number): undefined | string {
  if (typeof number === "undefined") {
    return undefined;
  }

  // Using Math.trunc to convert to an integer, effectively replacing _.toInteger
  const _number = Math.trunc(number);

  if (_number < 1000) {
    return String(_number);
  } else if (_number >= 1000 && _number < 1_000_000) {
    return (_number / 1000).toFixed(1) + "K";
  } else if (_number >= 1_000_000 && _number < 1_000_000_000) {
    return (_number / 1_000_000).toFixed(1) + "M";
  } else if (_number >= 1_000_000_000 && _number < 1_000_000_000_000) {
    return (_number / 1_000_000_000).toFixed(1) + "B";
  } else if (_number >= 1_000_000_000_000 && _number < 1_000_000_000_000_000) {
    return (_number / 1_000_000_000_000).toFixed(1) + "T";
  }
  return "-";
}

// Helper function to convert HSL variable to hex
export function hslToHex(hslValue: string): string {
  // Create a temporary div to compute the color
  const div = document.createElement("div");
  div.style.color = hslValue;
  document.body.appendChild(div);

  // Get the computed color value
  const color = window.getComputedStyle(div).color;
  document.body.removeChild(div);

  // Create a canvas to convert RGB to Hex
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return "#000000"; // fallback color

  ctx.fillStyle = color;
  return ctx.fillStyle;
}
