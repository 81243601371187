import { createFileRoute } from "@tanstack/react-router";
import { GlobalStateMachine } from "@/features/company-search/common/state/machine";
import { GlobalState } from "@/features/company-search/common/state/machine";
import { Root as CompanySearch } from "@/features/company-search/Root";
import { z } from "zod";

export const Route = createFileRoute("/")({
  validateSearch: z.object({
    time_period: z.array(z.string()).optional(),
    company_id: z.array(z.string()).optional(),
  }),
  component: () => {
    return <CompanySearch />;
  },
});
