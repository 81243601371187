/**
 * @component
 * @description Renders a component to display when a 500 Internal Server Error occurs.
 *
 * @remarks
 * The 500 Internal Server Error is a generic error message given when an unexpected
 * condition was encountered on the server and no more specific message is suitable.
 * This component provides a simple visual representation of this error to the user.
 *
 * @returns {JSX.Element} A div element containing the text "Error 500"
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/500 | HTTP 500 status code}
 */
export function Error500Component() {
  return <div>Error 500</div>;
}
