import * as React from "react";
import {
  AudioWaveform,
  Bot,
  ChevronRight,
  ChevronsUpDown,
  Command,
  Folder,
  Forward,
  Frame,
  LogOut,
  Moon,
  MoreHorizontal,
  PieChart,
  Plus,
  RotateCw,
  Settings2,
  SquareTerminal,
  Sun,
  SunMoon,
  Trash2,
} from "lucide-react";

import { Avatar, AvatarFallback, AvatarImage } from "@/core/components/third-party/ui/avatar";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/core/components/third-party/ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/core/components/third-party/ui/dropdown-menu";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarProvider,
  SidebarRail,
} from "@/core/components/third-party/ui/sidebar";
import { Auth } from "@/core/auth";
import { Button } from "@/core/components/third-party/ui/button";
import { useTheme } from "@/core/components/third-party/theme-provider";
import { cn } from "@/lib/utils";
import { toast } from "sonner";
import { notifyUser } from "@/core/notifications/notify";
import { useRouter, useSearch } from "@tanstack/react-router";
import { useMediaQuery } from "@/core/utils/hooks";
import { env } from "@/core/config/env";

function Logo() {
  return (
    <svg width="43" height="50" viewBox="0 0 43 50" fill="none" className="h-5 w-5 shrink-0">
      <path
        d="M33 25C33 17.8 27.2 12 20 12H16V5H18C29 5 38 14 38 25C38 36 29 45 18 45H16H6C5.4 45 5 44.6 5 44V12H2C0.9 12 0 12.9 0 14V15V46C0 48.2 1.8 50 4 50H18.4C32.2 50 42.9 38.8 43 25C43 11.1 32.9 0 19 0H13C11.9 0 11 0.9 11 2V36C11 37.1 11.9 38 13 38H20C27.2 38 33 32.2 33 25ZM28 25C28 29.4 24.4 33 20 33H17C16.4 33 16 32.6 16 32V18C16 17.4 16.4 17 17 17H19H20C24.4 17 28 20.6 28 25Z"
        fill="white"
      />
    </svg>
  );
}

// This is sample data.

interface Sidebar_07Props {
  children: React.ReactNode;
}

interface NavMainItem {
  title: string;
  url: string;
  icon?: React.ComponentType;
  isActive?: boolean;
  disabled?: boolean;
  isExternal?: boolean;
  items?: {
    title: string;
    url: string;
    disabled?: boolean;
    isExternal?: boolean;
  }[];
}

export function AppSidebar({ children }: Sidebar_07Props) {
  const auth = Auth.Machine.useState();
  const username = auth.readonly.user?.nickname;
  const email = auth.readonly.user?.email;
  const avatar = auth.readonly.user?.picture;
  const router = useRouter();

  const data = React.useMemo(() => {
    return {
      user: {
        name: username,
        email: email,
        avatar: avatar,
      },
      teams: [
        {
          name: "Deltabase",
          logo: Logo,
          plan: "Platform",
        },
        {
          name: "Acme Corp.",
          logo: AudioWaveform,
          plan: "Startup",
        },
        {
          name: "Evil Corp.",
          logo: Command,
          plan: "Free",
        },
      ],
      navMain: [
        {
          title: "Sector Search",
          url: "#",
          icon: SquareTerminal,
          isActive: false,
          disabled: true,
        },
        {
          title: "Company Search",
          url: "/",
          icon: Bot,
        },
        {
          title: "Support",
          url: "#",
          icon: Settings2,
          items: [
            {
              title: "Methodology & Guides",
              url: "#",
              disabled: true,
            },
            {
              title: "FAQs",
              url: env.VITE_FAQ,
              isExternal: true,
            },
            {
              title: "Terms & Conditions",
              url: env.VITE_TERMS_AND_CONDITIONS,
              isExternal: true,
            },
            {
              title: "Privacy Policy",
              url: env.VITE_PRIVACY_POLICY,
              isExternal: true,
            },
            {
              title: "Cookie Policy",
              url: env.VITE_COOKIE_POLICY,
              isExternal: true,
            },
          ],
        },
        {
          title: "Changelog",
          url: "#",
          icon: RotateCw,
          disabled: true,
        },
      ] as NavMainItem[],
      MyReports: [
        {
          name: "Create New Report",
          url: "#",
          icon: Frame,
          disabled: true,
        },
        {
          name: "Culture Essentials",
          url: "#",
          icon: PieChart,
          disabled: true,
        },
      ],
    };
  }, [username, email, avatar]);

  const [activeTeam, setActiveTeam] = React.useState(data.teams[0]);
  const theme = useTheme();
  return (
    <SidebarProvider>
      <div className="dark">
        <Sidebar collapsible="icon">
          <SidebarHeader>
            <SidebarMenu>
              <SidebarMenuItem>
                <DropdownMenu open={false}>
                  <DropdownMenuTrigger asChild>
                    <SidebarMenuButton
                      size="lg"
                      className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                    >
                      <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                        <activeTeam.logo className="size-4" />
                      </div>
                      <div className="grid flex-1 text-left text-sm leading-tight">
                        <span className="truncate font-semibold">{activeTeam.name}</span>
                        <span className="truncate text-xs">{activeTeam.plan}</span>
                      </div>
                      <ChevronsUpDown className="ml-auto" />
                    </SidebarMenuButton>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                    align="start"
                    side="bottom"
                    sideOffset={4}
                  >
                    <DropdownMenuLabel className="text-xs text-muted-foreground">Teams</DropdownMenuLabel>
                    {data.teams.map((team, index) => (
                      <DropdownMenuItem key={team.name} className="gap-2 p-2">
                        <div className="flex size-6 items-center justify-center rounded-sm border">
                          <team.logo className="size-4 shrink-0" />
                        </div>
                        {team.name}
                        <DropdownMenuShortcut>⌘{index + 1}</DropdownMenuShortcut>
                      </DropdownMenuItem>
                    ))}
                    <DropdownMenuSeparator />
                    <DropdownMenuItem className="gap-2 p-2">
                      <div className="flex size-6 items-center justify-center rounded-md border bg-background">
                        <Plus className="size-4" />
                      </div>
                      <div className="font-medium text-muted-foreground">Add team</div>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarHeader>
          <SidebarContent>
            <SidebarGroup>
              <SidebarGroupLabel>Platform</SidebarGroupLabel>
              <SidebarMenu>
                {data.navMain.map((item) => (
                  <Collapsible key={item.title} asChild defaultOpen={item.isActive} className="group/collapsible">
                    <SidebarMenuItem>
                      {item.items && item.items.length > 0 ? (
                        <>
                          <CollapsibleTrigger asChild>
                            <SidebarMenuButton tooltip={item.title}>
                              {item.icon && <item.icon />}
                              <span>{item.title}</span>
                              <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90 " />
                            </SidebarMenuButton>
                          </CollapsibleTrigger>
                          <CollapsibleContent>
                            <SidebarMenuSub>
                              {item.items?.map((subItem) => (
                                <SidebarMenuSubItem key={subItem.title}>
                                  <SidebarMenuSubButton asChild>
                                    <a href={subItem.url}>
                                      <span>{subItem.title}</span>
                                    </a>
                                  </SidebarMenuSubButton>
                                </SidebarMenuSubItem>
                              ))}
                            </SidebarMenuSub>
                          </CollapsibleContent>
                        </>
                      ) : (
                        <SidebarMenuButton
                          tooltip={item.title}
                          className={cn(item.disabled && "opacity-50 cursor-not-allowed")}
                          onClick={() => {
                            if (item.disabled) {
                              notifyUser.defaultMessage(`${item.title} is currently under development`);
                              return;
                            }
                            if (item.isExternal) {
                              window.open(item.url, "_blank", "noopener,noreferrer");
                              return;
                            }
                            // Add Tanstack Router navigation
                            router.navigate({
                              to: item.url,
                            });
                          }}
                        >
                          {item.icon && <item.icon />}
                          <span>{item.title}</span>
                        </SidebarMenuButton>
                      )}
                    </SidebarMenuItem>
                  </Collapsible>
                ))}
              </SidebarMenu>
            </SidebarGroup>
            <SidebarGroup className="group-data-[collapsible=icon]:hidden">
              <SidebarGroupLabel>My Reports</SidebarGroupLabel>
              <SidebarMenu>
                {data.MyReports.map((item) => (
                  <SidebarMenuItem key={item.name}>
                    <SidebarMenuButton
                      className={cn(item.disabled && "opacity-50 cursor-not-allowed")}
                      onClick={() => {
                        if (item.disabled) {
                          notifyUser.defaultMessage(`${item.name} is currently under development`);
                          return;
                        }
                        // Handle normal navigation here
                        window.location.href = item.url;
                      }}
                    >
                      <item.icon />
                      <span>{item.name}</span>
                    </SidebarMenuButton>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <SidebarMenuAction showOnHover>
                          <MoreHorizontal />
                          <span className="sr-only">More</span>
                        </SidebarMenuAction>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="w-48 rounded-lg" side="bottom" align="end">
                        <DropdownMenuItem
                          className={cn(item.disabled && "opacity-50 cursor-not-allowed")}
                          onClick={(e) => {
                            if (item.disabled) {
                              e.preventDefault();
                              notifyUser.defaultMessage(`${item.name} is currently under development`);
                              return;
                            }
                          }}
                        >
                          <Folder className="text-muted-foreground" />
                          <span>View Project</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem>
                          <Forward className="text-muted-foreground" />
                          <span>Share Project</span>
                        </DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem>
                          <Trash2 className="text-muted-foreground" />
                          <span>Delete Project</span>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </SidebarMenuItem>
                ))}
                <SidebarMenuItem>
                  <SidebarMenuButton className="text-sidebar-foreground/70">
                    <MoreHorizontal className="text-sidebar-foreground/70" />
                    <span>More</span>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              </SidebarMenu>
            </SidebarGroup>
          </SidebarContent>
          <SidebarFooter>
            <SidebarMenu>
              <SidebarMenuItem className="mb-2">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <SidebarMenuButton
                      size="lg"
                      className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                    >
                      <Button variant="outline" size="icon" className="h-8 w-8 rounded-lg min-w-8">
                        {theme.theme === "light" && (
                          <Sun className="h-[1.2rem] w-[1.2rem] text-sidebar-foreground rotate-0 scale-100 transition-all dark:-rotate-90" />
                        )}
                        {theme.theme === "dark" && (
                          <Moon className="h-[1.2rem] w-[1.2rem] text-sidebar-foreground rotate-0 scale-100 transition-all dark:-rotate-0" />
                        )}
                        {theme.theme === "system" && (
                          <SunMoon className="h-[1.2rem] w-[1.2rem] text-sidebar-foreground rotate-0 scale-100 transition-all dark:-rotate-90" />
                        )}
                        <span className="sr-only">Toggle theme</span>
                      </Button>
                      <div className="grid flex-1 text-left text-sm leading-tight">
                        <span className="truncate font-semibold">Theme</span>
                        <span className="truncate text-xs">{theme.theme}</span>
                      </div>
                      <ChevronsUpDown className="ml-auto size-4" />
                    </SidebarMenuButton>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                    side="bottom"
                    align="end"
                    sideOffset={4}
                  >
                    <DropdownMenuItem onClick={() => theme.setTheme("light")}>Light</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => theme.setTheme("dark")}>Dark</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => theme.setTheme("system")}>System</DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </SidebarMenuItem>

              <SidebarMenuItem>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <SidebarMenuButton
                      size="lg"
                      className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                    >
                      <Avatar className="h-8 w-8 rounded-lg border border-sidebar-border">
                        <AvatarImage src={data.user.avatar} alt={data.user.name} />
                        <AvatarFallback className="rounded-lg">CN</AvatarFallback>
                      </Avatar>
                      <div className="grid flex-1 text-left text-sm leading-tight">
                        <span className="truncate font-semibold">{data.user.name}</span>
                        <span className="truncate text-xs">{data.user.email}</span>
                      </div>
                      <ChevronsUpDown className="ml-auto size-4" />
                    </SidebarMenuButton>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                    side="bottom"
                    align="end"
                    sideOffset={4}
                  >
                    <DropdownMenuLabel className="p-0 font-normal">
                      <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                        <Avatar className="h-8 w-8 rounded-lg">
                          <AvatarImage src={data.user.avatar} alt={data.user.name} />
                          <AvatarFallback className="rounded-lg">CN</AvatarFallback>
                        </Avatar>
                        <div className="grid flex-1 text-left text-sm leading-tight">
                          <span className="truncate font-semibold">{data.user.name}</span>
                          <span className="truncate text-xs">{data.user.email}</span>
                        </div>
                      </div>
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem>
                      <LogOut />
                      Log out
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarFooter>
          <SidebarRail />
        </Sidebar>
      </div>
      <SidebarInset>{children}</SidebarInset>
    </SidebarProvider>
  );
}
