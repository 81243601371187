import { type DynamicStyle, CVA, type VariantProps, cn } from "@/core/utils/tailwind-toolkit/core";
import { CharacterLimiter } from "../../atoms/text/CharacterLimiter";

type IVariants = {
  displayDivider: {
    true: string;
    false: string;
  };
};

type IStyles = {
  container: DynamicStyle<IVariants>;
};

const cva = new CVA<IVariants>({
  defaultVariants: {
    displayDivider: false,
  },
});

const styles: IStyles = {
  container: cva.create("flex flex-col w-full gap-5", {
    variants: {
      displayDivider: {
        true: "pb-5 border-b-[1.5px] border-solid border-gray-200",
        false: "",
      },
    },
  }),
};

export type StyleProps = VariantProps<IVariants>;
type SupportTypes = Partial<StyleProps>;

type IPageHeaderSimple = {
  children: React.ReactNode;
} & SupportTypes;

export function PageHeaderSimpleContainer({ children, ...props }: IPageHeaderSimple) {
  return (
    <div data-testid="page-header-simple-container" className={styles.container(props)}>
      <div className="flex items-start justify-between gap-4 w-full">{children}</div>
    </div>
  );
}

PageHeaderSimpleContainer.TitleSection = TitleSection;
PageHeaderSimpleContainer.Title = Title;
PageHeaderSimpleContainer.SupportingText = SupportingText;
PageHeaderSimpleContainer.CharacterLimitedSupportingText = CharacterLimitedSupportingText;
PageHeaderSimpleContainer.Actions = Actions;

function TitleSection({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <div data-testid="page-header-title-section" className={cn("flex flex-col gap-1", className)}>
      {children}
    </div>
  );
}

function Title({ children }: IPageHeaderSimple) {
  return (
    <span data-testid="page-header-title" className="text-3xl font-semibold text-foreground">
      {children}
    </span>
  );
}

function SupportingText({ children }: IPageHeaderSimple) {
  return (
    <span data-testid="page-header-supporting-text" className=" text-sm font-normal text-muted-foreground">
      {children}
    </span>
  );
}

function CharacterLimitedSupportingText({ children }: { children: string }) {
  return (
    <CharacterLimiter
      data-testid="page-header-character-limited-text"
      characterLimit={100}
      classNames={{ text: "font-text-md-regular", readMore: "font-text-sm-semibold" }}
    >
      {children}
    </CharacterLimiter>
  );
}

function Actions({ children }: IPageHeaderSimple) {
  return (
    <div data-testid="page-header-actions" className="flex items-center gap-3">
      {children}
    </div>
  );
}
