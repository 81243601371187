import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";

const Collapsible = ({ children, ...props }: CollapsiblePrimitive.CollapsibleProps) => (
  <CollapsiblePrimitive.Root {...props} data-testid="collapsible">
    {children}
  </CollapsiblePrimitive.Root>
);

const CollapsibleTrigger = ({ children, ...props }: CollapsiblePrimitive.CollapsibleTriggerProps) => (
  <CollapsiblePrimitive.CollapsibleTrigger {...props} data-testid="collapsible-trigger">
    {children}
  </CollapsiblePrimitive.CollapsibleTrigger>
);

const CollapsibleContent = ({ children, ...props }: CollapsiblePrimitive.CollapsibleContentProps) => (
  <CollapsiblePrimitive.CollapsibleContent {...props} data-testid="collapsible-content">
    {children}
  </CollapsiblePrimitive.CollapsibleContent>
);

export { Collapsible, CollapsibleTrigger, CollapsibleContent };
