import { createFileRoute } from "@tanstack/react-router";
import { AnalyticReport } from "@/features/report";
import { z } from "zod";

export const Route = createFileRoute("/report")({
  validateSearch: z.object({
    company_id: z.array(z.string()),
    time_period: z.array(z.string()),
  }),
  component: () => <AnalyticReport />,
});
