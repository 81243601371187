import * as React from "react";
import { Blocks, ChevronRight, ChevronsUpDown, DownloadCloud, MoreVertical, Trash2 } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "@/core/components/third-party/ui/avatar";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/core/components/third-party/ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/core/components/third-party/ui/dropdown-menu";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarSeparator,
  useSidebar,
} from "@/core/components/third-party/ui/sidebar";
import { cn } from "@/core/utils/tailwind-toolkit/core";
import { acronym } from "@/core/utils/tailwind-toolkit/utils";
import { StateLayer } from "../../_common/state/state-layer";
import { Button } from "@/core/components/third-party/ui/button";
import { Checkbox } from "@/core/components/third-party/ui/checkbox";
import { notifyUser } from "@/core/notifications/notify";
import { useTheme } from "@/core/components/third-party/theme-provider";

export function DesktopControlPanel({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const isMobile = useSidebar();

  return (
    <Sidebar
      collapsible="none"
      className={cn(
        "sticky  w-full max-w-[300px] xl:max-w-[400px] lg:flex top-0 h-svh border-l",
        isMobile.open ? "hidden" : "",
      )}
      {...props}
    >
      <SidebarHeader className="h-16 border-b border-sidebar-border">
        <ControlPanelViewMenu />
      </SidebarHeader>
      <SidebarContent>
        <SidebarSeparator className="mx-0" />
        <Peerset />
      </SidebarContent>
      <SidebarFooter className="border-t border-sidebar-border">
        <ExportLaunchBay />
      </SidebarFooter>
    </Sidebar>
  );
}

function Peerset() {
  const state = StateLayer.useState();

  const companiesArray = Object.values(state.readonly.state.metadata.company.data);

  return (
    <>
      {companiesArray.length === 0 && (
        <div className="px-4 py-2">
          <div className="text-start text-sm text-muted-foreground bg-sidebar-accent p-2 rounded-lg">
            No companies in peer set. Add companies to your peer set to benchmark them.
          </div>
        </div>
      )}
      {companiesArray.length > 0 && (
        <React.Fragment>
          <SidebarGroup className="py-0">
            <Collapsible defaultOpen={true} className="group/collapsible">
              <SidebarGroupLabel
                asChild
                className="group/label w-full text-sm text-sidebar-foreground hover:bg-sidebar-accent hover:text-sidebar-accent-foreground"
              >
                <CollapsibleTrigger>
                  Peer Set
                  <ChevronRight className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-90" />
                </CollapsibleTrigger>
              </SidebarGroupLabel>
              <CollapsibleContent>
                <SidebarGroupContent>
                  <SidebarMenu>
                    {companiesArray.map((company) => (
                      <SidebarMenuItem key={company.company_id} className="flex items-center justify-between gap-2">
                        <SidebarMenuButton className="py-2 h-auto">
                          <div className="flex items-center gap-2">
                            <Avatar className="h-8 w-8 rounded-full border border-sidebar-border">
                              <AvatarImage src={company.company_logo_src} alt={company.company_name} />
                              <AvatarFallback className="rounded-full text-xs">
                                {acronym(company.company_name, 2)}
                              </AvatarFallback>
                            </Avatar>
                            <div className="flex flex-col gap-1">
                              {company.company_name}
                              {company.count_per_company.strength === "low" ? (
                                <span className="text-xs text-error-600">
                                  {company.count_per_company.compact_number} | reviews low
                                </span>
                              ) : (
                                <span className="text-xs text-muted-foreground">
                                  {company.count_per_company.compact_number} reviews
                                </span>
                              )}
                            </div>
                          </div>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    ))}
                  </SidebarMenu>
                </SidebarGroupContent>
              </CollapsibleContent>
            </Collapsible>
          </SidebarGroup>
          <SidebarSeparator className="mx-0" />
        </React.Fragment>
      )}
    </>
  );
}

export function ExportLaunchBay() {
  const theme = useTheme();
  const state = StateLayer.useState();
  const exportComponents = Object.entries(state.readonly.state.export.data);

  function handleRemoveComponentFromExport(instance_id: string) {
    state.mutable.events.common.removeFromExportables({ instance_id });
  }

  function handleToggleExportMode() {
    state.mutable.state.view = state.readonly.state.view === "read" ? "export" : "read";
    if (state.readonly.state.view === "read") {
      notifyUser.defaultMessage("EXPORT MODE: ON. Select analytics from your report to add them to your download.");
    }
  }

  async function handleExport() {
    await state.mutable.events.common.exportReport();
  }

  return (
    <Collapsible defaultOpen={true} className="group/collapsible">
      <SidebarGroupLabel
        asChild
        className="group/label w-full text-sm text-sidebar-foreground hover:bg-sidebar-accent hover:text-sidebar-accent-foreground"
      >
        <CollapsibleTrigger>
          Export
          <ChevronRight className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-90" />
        </CollapsibleTrigger>
      </SidebarGroupLabel>
      <CollapsibleContent className="flex flex-col h-full data-[state=open]:h-[400px]">
        <div className="flex-1 overflow-y-auto">
          <div className="flex flex-col gap-2 pt-3">
            <Button
              variant={"outline"}
              className={cn(
                "h-6 rounded-[6px] border shadow-sm px-2 text-xs lg:w-fit py-2 lg:max-w-[200px] w-full whitespace-nowrap ",
                state.readonly.state.view === "read" ? "" : "border-black dark:border-primary",
              )}
              onClick={handleToggleExportMode}
            >
              {state.readonly.state.view === "read" ? "Select Exportable Analytics" : "Hide Exportable Analytics"}
            </Button>
            {exportComponents.length === 0 ? (
              <div className="text-start text-xs font-medium border dark:border-accent text-muted-foreground bg-sidebar-accent p-2 rounded-lg">
                No report analytics selected for export. Click the button above ⬆️ to select exportable analytics and
                then click each analytics you want to export.
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                {exportComponents.map(([instance_id, data]) => {
                  const name = state.readonly.state.components[instance_id].name;
                  return (
                    <div
                      className="flex items-center justify-between p-2 bg-sidebar-accent border dark:border-accent dark:bg-accent/25 rounded-lg "
                      key={instance_id}
                    >
                      <div className="flex items-center gap-2">
                        <Checkbox
                          checked
                          id={`export-${instance_id}`}
                          className="data-[state=checked]:bg-black data-[state=checked]:dark:bg-primary border-1 border-black dark:border-primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveComponentFromExport(instance_id);
                          }}
                        />
                        <span className="text-xs text-sidebar-foreground">{name}</span>
                      </div>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" size="icon" className="h-6 w-6">
                            <MoreVertical className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuLabel>Export Options</DropdownMenuLabel>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem onClick={() => handleRemoveComponentFromExport(instance_id)}>
                            <Trash2 />
                            Remove from export
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="sticky bottom-0  w-full">
          <Button
            variant={theme.theme === "dark" ? "white" : "black"}
            className={cn("w-full disabled:cursor-not-allowed ")}
            onClick={handleExport}
            disabled={exportComponents.length === 0}
          >
            Download Report
            <DownloadCloud className="ml-auto size-4" />
          </Button>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
}

function ControlPanelViewMenu() {
  const { isMobile } = useSidebar();

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <div className="h-8 w-8 rounded-lg bg-muted flex items-center justify-center">
                <Blocks className="text-sidebar-primary size-5" />
              </div>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold"> Report Manager</span>
                <span className="truncate text-xs">Control Panel</span>
              </div>
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            side={isMobile ? "bottom" : "right"}
            align="start"
            sideOffset={4}
          >
            <DropdownMenuLabel className="p-0 font-normal">
              <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">Control Panel</span>
                  <span className="truncate text-xs">Configure your search</span>
                </div>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem>
                <Blocks />
                Report Manager
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
