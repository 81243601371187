// Define the structure of the ClientError class
interface ClientErrorConstructor {
  name: string;
  message: string;
}

export class ClientError extends Error {
  constructor(props: ClientErrorConstructor) {
    super(props.message);
    this.name = props.name;
  }
}
