import { CacheQueryNotification } from "@/core/cache/core";
import { ClientError, Error } from "@/core/errors";
import { http } from "@/core/http/core";
import { notification } from "@/core/notifications/core";
import { AxiosResponse } from "axios";

export const cacheKey = "METADATA" as const;

export interface Metadata {
  meta_data_name: string;
  meta_data_value: string;
  meta_data_description: string | null;
  meta_data_parent_value: string | null;
}

export interface IRequest {
  headers: {
    token: `Bearer ${string}`;
  };
}

export interface IResponse {
  status: number;
  code: string;
  data: Metadata[];
}

interface CreateQueryConfig<T> {
  enabled?: boolean;
  onSuccess?: (data: IResponse["data"]) => void;
  onError?: (error: Error) => void;
  onNotification?: CacheQueryNotification;
  transform?: (data: IResponse["data"]) => T;
  auth?: {
    accessToken?: string | null;
  };
  queryKey?: string[];
  captureError?: ReturnType<typeof Error.hooks.useCaptureError>;
  notifications?: CacheQueryNotification;
}

export function createMetadataQuery<T>(props: CreateQueryConfig<T>) {
  return {
    queryKey: [cacheKey, ...(props.queryKey ?? [])],
    queryFn: async () => {
      const accessToken = props.auth?.accessToken;
      if (!accessToken) {
        props.captureError?.(
          new ClientError({
            name: "No access token found",
            message: "No access token found",
          }),
        );
        notification.error({
          message: "Error",
          data: {
            description: "Failed to fetch metadata",
          },
        });
        return;
      }
      const response = await http.dataApi.get<IResponse>("/meta_data", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.status !== 200) {
        throw new ClientError({
          name: "Client Error",
          message: `Failed to fetch metadata: ${response.data.code} status ${response.data.status}`,
        });
      }
      const transformed = props.transform ? props.transform(response.data.data) : null;
      return { response, transformed };
    },
    enabled: props.enabled,
    meta: {
      notification: {
        disable: false,
        onError: {
          message: "Error",
          data: {
            description: "Failed to fetch metadata",
          },
        },
      },
      ...props.onNotification,
    },
  };
}
