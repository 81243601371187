import React from "react";

interface Error406ComponentProps {
  children: React.ReactNode;
}

/**
 * @component
 * @description Renders a component to display when a 406 Not Acceptable error occurs.
 * This is typically used when the user accesses the application from an unsupported screen size (e.g., mobile).
 *
 * @remarks
 * The 406 Not Acceptable status code indicates that the server understood the request but cannot produce
 * a response matching the list of acceptable values defined by the request's proactive content negotiation headers.
 * In this context, it's adapted to represent incompatibility with the client's screen size.
 *
 * While many web applications handle responsive design on the client-side, this component can be used
 * when implementing server-side checks for screen size compatibility.
 *
 * @see {@link https://en.wikipedia.org/wiki/List_of_HTTP_status_codes#4xx_client_errors | HTTP 406 status code}
 */
export function Error406Component(props: Error406ComponentProps) {
  return (
    <React.Fragment>
      <div className="md:hidden">
        <span>This application is not supported on mobile devices.</span>
      </div>
      <div className="hidden md:block">{props.children}</div>
    </React.Fragment>
  );
}
