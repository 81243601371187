import { useAuth0, User } from "@auth0/auth0-react";
import { RedirectLoginOptions } from "@auth0/auth0-react";
import { AppState } from "@auth0/auth0-react";
import { LogoutOptions } from "@auth0/auth0-react";
import { createValtioContextProvider } from "../utils/valtio/core";
import { useEffect } from "react";
import { useState } from "react";

export class AuthMachine {
  isLoading: boolean; // Indicates if Auth0 is still initializing
  isAuthenticated: boolean; // Whether the user is authenticated
  error: Error | null; // Any authentication errors
  user: User | null; // User profile information
  login: ((options?: RedirectLoginOptions<AppState>) => void) | null; // Method to initiate login
  logout: ((options?: LogoutOptions) => void) | null; // Method to log out
  accessToken: string | null; // Method to retrieve access token

  constructor() {
    this.isLoading = false;
    this.isAuthenticated = false;
    this.error = null;
    this.user = null;
    this.login = null;
    this.logout = null;
    this.accessToken = null;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setIsAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
  }

  setError(error: Error | null) {
    this.error = error;
  }

  setUser(user: User | null) {
    this.user = user;
  }

  setLogin(login: (options?: RedirectLoginOptions<AppState>) => void) {
    this.login = login;
  }

  setLogout(logout: (options?: LogoutOptions) => void) {
    this.logout = logout;
  }

  setAccessToken(accessToken: string | null) {
    this.accessToken = accessToken;
  }
}

const Machine = createValtioContextProvider<AuthMachine>("AuthMachine");

function Injector({ children }: { children: React.ReactNode }) {
  const auth = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    const getToken = async () => {
      try {
        if (auth.isLoading) return;
        if (auth.isAuthenticated) {
          const token = await auth.getAccessTokenSilently();
          setAccessToken(token);
        }
      } catch (error) {
        console.error("Error fetching access token:", error);
        setAccessToken(null);
      }
    };

    getToken();
  }, [auth.isAuthenticated, auth.isLoading, auth.getAccessTokenSilently, auth]);

  const machine = new AuthMachine();

  machine.setIsAuthenticated(auth.isAuthenticated);
  machine.setAccessToken(accessToken);
  machine.setIsLoading(auth.isLoading);
  machine.setUser(auth.user ?? null);
  machine.setLogin(auth.loginWithRedirect);
  machine.setLogout(auth.logout);

  return <Machine.Injector value={machine}>{children}</Machine.Injector>;
}

export const Auth = { Machine, Injector };
