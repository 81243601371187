import { ComponentPropsWithoutRef, lazy } from "react";
import { IConfig as EmployeeVoiceTrendsPerTopicConfig } from "../data-components/culture/employee-voice-trends-per-topic/index";

// Lazy load multiple components
export const DataComponents = {
  EMPLOYEE_VOICE_TRENDS_PER_TOPIC: {
    id: "EMPLOYEE_VOICE_TRENDS_PER_TOPIC",
    Component: lazy(() =>
      import("../data-components/culture/employee-voice-trends-per-topic/index").then((module) => ({
        default: module.Data_Component_Employee_Voice_Trends_Per_Topic,
      })),
    ),
  },
  COMPANY_PERSONALITIES: {
    id: "COMPANY_PERSONALITIES",
    Component: lazy(() =>
      import("../data-components/culture/company-personalities/index").then((module) => ({
        default: module.Data_Component_Company_Personalities,
      })),
    ),
  },
  EMPLOYEE_VOICE_EXTRACTS: {
    id: "EMPLOYEE_VOICE_EXTRACTS",
    Component: lazy(() =>
      import("../data-components/culture/employee-voice-extracts/index").then((module) => ({
        default: module.Data_Component_Employee_Voice_Extracts,
      })),
    ),
  },
  EMPLOYEE_VOICE_RANKING: {
    id: "EMPLOYEE_VOICE_RANKING",
    Component: lazy(() =>
      import("../data-components/culture/employee-voice-ranking/index").then((module) => ({
        default: module.Data_Component_Employee_Voice_Ranking,
      })),
    ),
  },
  // Add more components as needed
};

interface GetComponentProps {
  id: string;
  options?: {
    throwError?: boolean;
  };
}

export function getDataComponent({ id, options }: GetComponentProps) {
  if (!(id in DataComponents)) {
    if (options?.throwError) {
      throw new Error(`Component with id ${id} not found`);
    }
    return null;
  }
  return DataComponents[id as keyof typeof DataComponents];
}
