import { env } from "@/core/config/env";
import axios from "axios";

const dataApi = axios.create({
  baseURL: env.VITE_DATA_API_URL,
  paramsSerializer: {
    indexes: null, // by default: false
  },
});

export const http = {
  dataApi,
};
