import { Auth } from "./machine";
import { Auth0Injector } from "./auth0";

export function AuthInjector({ children }: { children: React.ReactNode }) {
  return (
    <Auth0Injector>
      <Auth.Injector>{children}</Auth.Injector>
    </Auth0Injector>
  );
}
