import { QueryLayer } from "./_common/state/query-layer";
import { StateLayer, stateFactory } from "./_common/state/state-layer";
import { Report } from "./report/Index";
import { Error } from "@/core/errors";
import { uid } from "radash";

export function AnalyticReport() {
  const captureError = Error.hooks.useCaptureError();

  const state = stateFactory.createInstance({
    id: "TEST_REPORT",
    name: "Test Report",
    captureError,
    components: {
      [`EMPLOYEE_VOICE_RANKING${uid(5)}`]: {
        type: "EMPLOYEE_VOICE_RANKING",
        name: "Employee Voice Ranking",
      },
      [`EMPLOYEE_VOICE_EXTRACTS${uid(5)}`]: {
        type: "EMPLOYEE_VOICE_EXTRACTS",
        name: "Employee Voice Extracts",
      },
      [`EMPLOYEE_VOICE_TRENDS_PER_TOPIC${uid(5)}`]: {
        type: "EMPLOYEE_VOICE_TRENDS_PER_TOPIC",
        name: "Employee Voice Trends Per Topic",
      },
      [`COMPANY_PERSONALITIES${uid(5)}`]: {
        type: "COMPANY_PERSONALITIES",
        name: "Company Personalities",
      },
    },
  });

  return (
    <StateLayer.Injector value={state}>
      <QueryLayer.Injector>
        <Report />
      </QueryLayer.Injector>
    </StateLayer.Injector>
  );
}
