import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { notification, PublishProps } from "@/core/notifications/core";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export interface CacheQueryNotification {
  disable?: boolean;
  onSuccess?: PublishProps;
  onError?: PublishProps;
}

export interface CacheMutationNotification {
  disable?: boolean;
  onMutate?: PublishProps;
  onSuccess?: PublishProps;
  onError?: PublishProps;
}

declare module "@tanstack/react-query" {
  interface Register {
    queryMeta: {
      notification: CacheQueryNotification;
    };
    mutationMeta: {
      notification: CacheMutationNotification;
    };
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 86400000,
    },
  },
  queryCache: new QueryCache({
    onSuccess: (_, payload) => {
      if (payload.meta?.notification.disable || !payload.meta?.notification.onSuccess) return;
      notification.success(payload.meta?.notification.onSuccess);
    },
    onError: (_, payload) => {
      if (payload.meta?.notification.disable || !payload.meta?.notification.onError) return;
      notification.error(payload.meta?.notification.onError);
    },
  }),
  mutationCache: new MutationCache({
    onMutate: (_, payload) => {
      if (payload.meta?.notification.disable || !payload.meta?.notification.onMutate) return;
      notification.info(payload.meta?.notification.onMutate);
    },
    onSuccess: (_, __, ___, payload) => {
      if (payload.meta?.notification.disable || !payload.meta?.notification.onSuccess) return;
      notification.success(payload.meta?.notification.onSuccess);
    },
    onError: (_, __, ___, payload) => {
      if (payload.meta?.notification.disable || !payload.meta?.notification.onError) return;
      notification.error(payload.meta?.notification.onError);
    },
  }),
});

// dependency injection
interface InjectorProps {
  children: React.ReactNode;
}
export function Injector({ children }: InjectorProps) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
