import { GlobalStateMachine, GlobalState } from "./machine";
import { useQueries } from "@tanstack/react-query";
import { createCompaniesQuery } from "../endpoints/companies";
import { Auth } from "@/core/auth";
import { Error } from "@/core/errors";
import { TransformedCompany } from "./machine";
import {
  HeadcountTransformer,
  NSSTransformer,
  PrevalenceTransformer,
  RatingTransformer,
  ReviewCountTransformer,
} from "../transformers/measures";
import { createMetadataQuery, Metadata } from "../endpoints/metadata";
import { useEffect } from "react";

interface IProps {
  children?: React.ReactNode;
}
export function DataInjector(props: IProps) {
  const auth = Auth.Machine.useState();
  const captureError = Error.hooks.useCaptureError();
  const globalState = GlobalState.machine.useState();

  const result = useQueries({
    queries: [
      createMetadataQuery({
        enabled: !!auth.readonly.accessToken,
        auth: {
          accessToken: auth.readonly.accessToken,
        },
        queryKey: ["COMPANY_SEARCH"],
        captureError,
        transform(data) {
          const metadata: Record<string, Metadata[]> = {};
          data.forEach((item) => {
            if (!metadata[item.meta_data_name]) {
              metadata[item.meta_data_name] = [];
            }
            metadata[item.meta_data_name].push(item);
          });
          return metadata;
        },
      }),
      createCompaniesQuery({
        enabled: !!auth.readonly.accessToken,
        auth: {
          accessToken: auth.readonly.accessToken,
        },
        params: {
          start_row: globalState.readonly.page_start,
          end_row: globalState.readonly.page_end,
          company_name: globalState.readonly.company_name,
          sort_field: globalState.readonly.sort_field,
          sort_direction: globalState.readonly.sort_direction,
          sector: Object.keys(globalState.readonly.sector ?? {}),
          use_new_sectors: "true",
          year: ["2019", "2020", "2021", "2022", "2023"],
        },
        captureError,
        transform(data) {
          const nssTransformer = new NSSTransformer();
          const prevalenceTransformer = new PrevalenceTransformer();
          const countTransformer = new ReviewCountTransformer();
          const ratingTransformer = new RatingTransformer();
          const headcountTransformer = new HeadcountTransformer();
          const newData: Array<TransformedCompany> = data.map((company) => {
            return {
              ...company,
              nss_per_company: {
                percentage: nssTransformer.toPercentage(company.nss_per_company),
                strength: nssTransformer.toStrength(company.nss_per_company),
                sentiment: nssTransformer.toSentiment(company.nss_per_company),
                percentage_string: nssTransformer.toPercentageString(company.nss_per_company),
              },
              prevalence_per_company: {
                percentage: prevalenceTransformer.toPercentage(company.prevalence_per_company),
                percentage_string: prevalenceTransformer.toPercentageString(company.prevalence_per_company),
              },
              count_per_company: {
                count: countTransformer.toNumber(company.count_per_company),
                compact_number: countTransformer.toCompactNumber(company.count_per_company),
                strength: countTransformer.toStrength(company.signal_strength),
              },
              rating_per_company: {
                rating: ratingTransformer.toNumber(company.rating_per_company),
              },
              sectors: {
                primary: company.sectors.filter((sector) => sector.sector_is_primary) ?? null,
                secondary: company.sectors.filter((sector) => !sector.sector_is_primary),
              },
              company_headcount: {
                compact_number: headcountTransformer.toCompactNumber(company.company_headcount?.toString()),
              },
            };
          });
          return newData;
        },
      }),
    ],
  });

  useEffect(() => {
    if (!result) return;
    globalState.mutable.setMetadata(result[0].data?.transformed ?? {});
    globalState.mutable.setMetadataIsPending(result[0].isPending);

    globalState.mutable.setCompanies(result[1].data?.transformed ?? []);
    globalState.mutable.setTotalCount(result[1].data?.response.data.count ?? 0);
    globalState.mutable.setCompaniesIsPending(result[1].isPending);

    globalState.mutable.setError(result[0].isError || result[1].isError);
  }, [result, globalState.mutable]);

  return <>{props.children}</>;
}
