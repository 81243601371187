import * as React from "react";
import {
  AudioWaveform,
  BadgeCheck,
  Bell,
  Blocks,
  BookOpen,
  Bot,
  Check,
  ChevronRight,
  ChevronsUpDown,
  Command,
  CreditCard,
  Filter,
  Folder,
  Forward,
  Frame,
  GalleryVerticalEnd,
  LogOut,
  Map,
  MoreHorizontal,
  PieChart,
  Plus,
  RotateCw,
  Search,
  Settings2,
  Sparkles,
  SquareTerminal,
  Trash2,
} from "lucide-react";

import { Avatar, AvatarFallback, AvatarImage } from "@/core/components/third-party/ui/avatar";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/core/components/third-party/ui/breadcrumb";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/core/components/third-party/ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/core/components/third-party/ui/dropdown-menu";
import { Separator } from "@/core/components/third-party/ui/separator";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarProvider,
  SidebarRail,
  SidebarSeparator,
  SidebarTrigger,
  useSidebar,
} from "@/core/components/third-party/ui/sidebar";
import { Outlet, useNavigate, useSearch } from "@tanstack/react-router";
import { cn } from "@/core/utils/tailwind-toolkit/core";
import { GlobalState } from "../common/state/machine";
import { useMemo } from "react";
import { TransformedCompany } from "../common/state/machine";
import { acronym } from "@/core/utils/tailwind-toolkit/utils";
import { Badge } from "@/core/components/third-party/ui/badge";
import { Button } from "@/core/components/third-party/ui/button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
} from "@/core/components/third-party/ui/select";
import { SelectValue } from "@radix-ui/react-select";
import { TimePeriodSelect } from "./time-period-select";
import { notifyUser } from "@/core/notifications/notify";

const sidebarRightData = {
  user: {
    name: "shadcn",
    email: "m@example.com",
    avatar: "/avatars/shadcn.jpg",
  },
  calendars: [
    {
      name: "Build Your Peer Set",
      items: ["Personal", "Work", "Family"],
    },
    {
      name: "Choose your sector filters",
      items: ["Holidays", "Birthdays"],
    },
    {
      name: "Set your time filters",
      items: ["Travel", "Reminders", "Deadlines"],
    },
    {
      name: "Choose a report type",
      items: ["Culture Essentials"],
    },
    {
      name: "Benchmark",
      items: ["Travel", "Reminders", "Deadlines"],
    },
  ],
};

export function DesktopControlPanel({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const globalState = GlobalState.machine.useState();
  const isMobile = useSidebar();
  const navigate = useNavigate();
  const searchParams = useSearch({ from: "/" });

  const numberOfWarnigs = useMemo(() => {
    return Object.values(globalState.readonly.peerset ?? {}).filter(
      (company) => company.count_per_company.strength === "low",
    ).length;
  }, [globalState.readonly.peerset]);

  const peersetSize = Object.keys(globalState.readonly.peerset).length;

  return (
    <Sidebar
      collapsible="none"
      className={cn(
        "sticky  w-full max-w-[300px] xl:max-w-[400px] lg:flex top-0 h-svh border-l",
        isMobile.open ? "hidden" : "",
      )}
      {...props}
    >
      <SidebarHeader className="h-16 border-b border-sidebar-border">
        <NavUser user={sidebarRightData.user} />
      </SidebarHeader>
      <SidebarContent>
        {/* <DatePicker /> */}
        <SidebarSeparator className="mx-0" />
        <Calendars calendars={sidebarRightData.calendars} />
      </SidebarContent>
      <SidebarFooter className="border-t border-sidebar-border ">
        <span className="group/label w-full text-sm font-semibold text-sidebar-foreground hover:bg-sidebar-accent hover:text-sidebar-accent-foreground">
          Ready to Benchmark
        </span>
        <span className="text-xs text-sidebar-foreground">Peer set size: {peersetSize}</span>
        <span className="text-xs text-sidebar-foreground">
          Sectors: {Object.keys(globalState.readonly.peerset ?? {}).length}
        </span>
        <span className={cn("text-xs", numberOfWarnigs > 0 ? "text-error-500" : "text-sidebar-foreground")}>
          Warnings: {numberOfWarnigs}
        </span>
        <div className="py-2">
          <TimePeriodSelect className="h-6 rounded-[6px] border bg-transparent shadow-sm px-2 text-xs text-foreground hover:bg-muted dark:text-foreground w-fit py-2" />
        </div>
        <Button
          variant="default"
          onClick={() => {
            const company_ids = Object.values(globalState.readonly.peerset).map((company) => company.company_id);
            if (company_ids.length === 0) {
              notifyUser.defaultError(
                "Please add at least one company to your peer set before launching the benchmark",
              );
            }
            navigate({
              to: "/report",
              search: () => {
                return {
                  company_id: company_ids,
                  time_period: searchParams.time_period ?? [],
                };
              },
            });
          }}
        >
          Launch Benchmark <ChevronRight className="ml-auto size-4" />
        </Button>
      </SidebarFooter>
    </Sidebar>
  );
}

function Calendars({
  calendars,
}: {
  calendars: {
    name: string;
    items: string[];
  }[];
}) {
  const globalState = GlobalState.machine.useState();

  function handleRemoveFromPeerset(companyId: string) {
    globalState.mutable.removeFromPeerset(companyId);
  }

  // const companies = useMemo(() => {
  //   return Object.entries(globalState.readonly.peerset).map(([groupName, companies]) => ({
  //     groupName,
  //     companies: Object.values(companies) as TransformedCompany[],
  //   })) as Array<{ groupName: string; companies: TransformedCompany[] }>;
  // }, [globalState.readonly.peerset]);

  return (
    <>
      {Object.values(globalState.readonly.peerset).length === 0 && (
        <div className="px-4 py-2">
          <div className="text-start text-sm text-muted-foreground bg-sidebar-accent p-2 rounded-lg">
            No companies in peer set. Add companies to your peer set to benchmark them.
          </div>
        </div>
      )}
      {Object.values(globalState.readonly.peerset).length > 0 && (
        <React.Fragment>
          <SidebarGroup className="py-0">
            <Collapsible defaultOpen={true} className="group/collapsible">
              <SidebarGroupLabel
                asChild
                className="group/label w-full text-sm text-sidebar-foreground hover:bg-sidebar-accent hover:text-sidebar-accent-foreground"
              >
                <CollapsibleTrigger>
                  Peer Set
                  <ChevronRight className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-90" />
                </CollapsibleTrigger>
              </SidebarGroupLabel>
              <CollapsibleContent>
                <SidebarGroupContent>
                  <SidebarMenu>
                    {Object.values(globalState.readonly.peerset).map((company) => (
                      <SidebarMenuItem key={company.company_id} className="flex items-center justify-between gap-2">
                        <SidebarMenuButton className="py-2 h-auto">
                          <div className="flex items-center gap-2">
                            <Avatar className="h-8 w-8 rounded-full border border-sidebar-border">
                              <AvatarImage src={company.company_logo_src} alt={company.company_name} />
                              <AvatarFallback className="rounded-full text-xs">
                                {acronym(company.company_name, 2)}
                              </AvatarFallback>
                            </Avatar>
                            <div className="flex flex-col gap-1">
                              {company.company_name}
                              {company.count_per_company.strength === "low" ? (
                                <span className="text-xs text-error-600">
                                  {company.count_per_company.compact_number} | reviews low
                                </span>
                              ) : (
                                <span className="text-xs text-muted-foreground">
                                  {company.count_per_company.compact_number} reviews
                                </span>
                              )}
                            </div>
                          </div>
                        </SidebarMenuButton>
                        <Button variant="ghost" size="icon" onClick={() => handleRemoveFromPeerset(company.company_id)}>
                          <Trash2 className="size-4" />
                        </Button>
                      </SidebarMenuItem>
                    ))}
                  </SidebarMenu>
                </SidebarGroupContent>
              </CollapsibleContent>
            </Collapsible>
          </SidebarGroup>
          <SidebarSeparator className="mx-0" />
        </React.Fragment>
      )}
    </>
  );
}

function NavUser({
  user,
}: {
  user: {
    name: string;
    email: string;
    avatar: string;
  };
}) {
  const { isMobile } = useSidebar();

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <div className="h-8 w-8 rounded-lg bg-muted flex items-center justify-center">
                <Blocks className="text-sidebar-primary size-5" />
              </div>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold"> Peer Set Manager</span>
                <span className="truncate text-xs">Control Panel</span>
              </div>
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            side={isMobile ? "bottom" : "right"}
            align="start"
            sideOffset={4}
          >
            <DropdownMenuLabel className="p-0 font-normal">
              <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">Control Panel</span>
                  <span className="truncate text-xs">Configure your search</span>
                </div>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem>
                <Blocks />
                Peer Set Manager
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
