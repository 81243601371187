import { Auth0Provider } from "@auth0/auth0-react";
import { env } from "../config/env";
import { withAuthenticationRequired as withAuthenticationRequiredAuth0 } from "@auth0/auth0-react";

interface Auth0InjectorProps {
  children: React.ReactNode;
}

export function Auth0Injector({ children }: Auth0InjectorProps) {
  return (
    <Auth0Provider
      domain={env.VITE_AUTH0_DOMAIN}
      clientId={env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: env.VITE_AUTH0_REDIRECT_URI,
        audience: env.VITE_AUTH0_AUDIENCE,
      }}
      // Add this back to stop white screen on refresh
      // cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
}

export const withAuthenticationRequired = withAuthenticationRequiredAuth0;
