import { CVA, type DynamicStyle, type VariantProps } from "@/core/utils/tailwind-toolkit/core";
import { type IProps as IButtonProps } from "@/core/components/atoms/Button";
import * as TooltipPrim from "@radix-ui/react-tooltip";

type IVariants = {
  displaySupportingText: {
    true: string;
    false: string;
  };
  theme: {
    light: string;
    dark: string;
  };
};

type IStyles = {
  content: {
    container: DynamicStyle<IVariants>;
    arrow: DynamicStyle<IVariants>;
    title: DynamicStyle<IVariants>;
    body: DynamicStyle<IVariants>;
  };
};

const cva = new CVA<IVariants>({
  defaultVariants: {
    displaySupportingText: false,
    theme: "dark",
  },
});

const styles: IStyles = {
  content: {
    container: cva.create("shadow-shadow-lg rounded-lg flex flex-col gap-1", {
      variants: {
        displaySupportingText: {
          false: "px-3 py-2",
          true: "p-3 max-w-[320px]",
        },
        theme: {
          light: "bg-base-white ",
          dark: "bg-gray-900",
        },
      },
    }),
    arrow: cva.create("", {
      variants: {
        theme: {
          light: "fill-base-white ",
          dark: "fill-gray-900",
        },
      },
    }),
    title: cva.create("font-text-xs-semibold", {
      variants: {
        theme: {
          light: "text-gray-700",
          dark: "text-base-white",
        },
      },
    }),
    body: cva.create("font-text-xs-medium", {
      variants: {
        theme: {
          light: "text-gray-600",
          dark: "text-gray-300",
        },
        displaySupportingText: {
          true: "block",
          false: "hidden",
        },
      },
    }),
  },
};

export type StyleProps = VariantProps<IVariants>;
type SupportTypes = Partial<StyleProps> & {
  provider?: TooltipPrim.TooltipProviderProps;
  root?: TooltipPrim.TooltipProps;
  portal?: TooltipPrim.TooltipPortalProps;
} & TooltipPrim.TooltipContentProps &
  Omit<IButtonProps, "classNames">;

type IProps = {
  title: string;
  supportingText?: string | React.ReactNode;
  disableHoverableContent?: boolean;
  classNames?: {
    content?: {
      container?: string;
      arrow?: string;
      title?: string;
      body?: string;
    };
    button?: IButtonProps["classNames"];
  };
  trigger?: React.ReactNode; // New prop for custom trigger
} & SupportTypes;

export function TooltipContainer({ classNames, trigger, ...props }: IProps) {
  return (
    <TooltipPrim.Provider {...props.provider}>
      <TooltipPrim.Root {...props.root} open={props.disableHoverableContent ? false : undefined}>
        <TooltipPrim.Trigger>{trigger}</TooltipPrim.Trigger>
        <TooltipPrim.Portal {...props.portal}>
          <TooltipPrim.Content avoidCollisions sideOffset={props.hierarchy?.includes("link") ? -4 : 5} {...props}>
            <div className={styles.content.container(props, classNames?.content?.container)}>
              <span className={styles.content.title(props, classNames?.content?.title)}>{props.title}</span>
              {typeof props.supportingText === "string" ? (
                <span className={styles.content.body(props, classNames?.content?.body)}>{props.supportingText}</span>
              ) : (
                <div className={styles.content.body(props, classNames?.content?.body)}>{props.supportingText}</div>
              )}
            </div>
            <TooltipPrim.Arrow className={styles.content.arrow(props, classNames?.content?.arrow)} />
          </TooltipPrim.Content>
        </TooltipPrim.Portal>
      </TooltipPrim.Root>
    </TooltipPrim.Provider>
  );
}
