import { Card, CardContent, CardHeader } from "@/core/components/third-party/ui/card";
import { Button } from "@/core/components/third-party/ui/button";
import { SearchXIcon, SendIcon } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/core/components/third-party/ui/dialog";
import { Label } from "@/core/components/third-party/ui/label";

export function NoResultsFound() {
  const email = "platform.orders@deltabase.io";
  const subject = encodeURIComponent("Deltabase new company data request");
  const body = encodeURIComponent(`Company: Sainsbury's
URL: sainsburys.co.uk
Description: Supermarket, online retail and banking group

Add more companies below using the above format.
`);

  const handleOnClickButton = () => {
    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  };

  return (
    <Card className="w-full mx-auto text-center p-6 shadow-none border-dashed">
      <CardHeader className="pb-2">
        <div className="flex justify-center mb-4">
          <Button variant="outline" size="icon" className="pointer-events-none shadow-sm">
            <SearchXIcon className="w-6 h-6" />
          </Button>
        </div>
        <span data-testid="no-results-title" className="text-lg font-semibold">
          No Companies Found
        </span>
      </CardHeader>
      <CardContent className="flex flex-col items-center">
        <p data-testid="no-results-description" className="text-muted-foreground mb-6 text-sm max-w-[352px]">
          Can't find the company you're looking for? Click below to send an email request for a new company.
        </p>
        <div className="flex gap-4 justify-center">
          <Dialog>
            <DialogTrigger asChild>
              <Button variant="secondary">Create Company Request</Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle className="mb-3">Company Request Template</DialogTitle>
                <div data-testid="email-instructions" className="bg-muted dark:bg-accent/45 p-4 rounded-lg text-sm">
                  To request a company to be added to your data set, please email
                  <a
                    data-testid="email-link"
                    href={`mailto:${email}?subject=${subject}&body=${body}`}
                    target="_top"
                    className="ml-1 underline"
                  >
                    platform.orders@deltabase.io
                  </a>
                  . Include the Company Name, Description and website URL. A member of the team will then reach out to
                  confirm your order and next steps.
                </div>
              </DialogHeader>
              <div className="flex flex-col gap-4">
                <div className="space-y-2">
                  <Label>Request Email</Label>
                  <div className="flex items-center gap-2">
                    <div data-testid="email-display" className="flex-1 p-2 border rounded-md bg-background">
                      <span className="text-sm">{email}</span>
                    </div>
                    <Button onClick={handleOnClickButton} size="default">
                      Open in Mail
                    </Button>
                  </div>
                </div>
                <div className="space-y-2">
                  <Label>Example Email</Label>
                  <div data-testid="example-email" className="p-3 border rounded-md space-y-2">
                    <p data-testid="example-subject" className="text-sm font-medium">
                      Subject: Deltabase new company data request
                    </p>
                    <div className="space-y-1 text-sm">
                      <p data-testid="example-company">
                        <span className="font-medium">Company:</span> Sainsbury's
                      </p>
                      <p data-testid="example-url">
                        <span className="font-medium">URL:</span> sainsburys.co.uk
                      </p>
                      <p data-testid="example-description">
                        <span className="font-medium">Description:</span> Supermarket, online retail and banking group
                      </p>
                    </div>
                    <p data-testid="example-note" className="text-xs italic text-muted-foreground">
                      Add more companies below using the above format.
                    </p>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </CardContent>
    </Card>
  );
}
