import { ScrollArea } from "@/core/components/third-party/scroll-area";
import { Card, CardSkeleton } from "./Card";
import {
  Select,
  SelectItem,
  SelectLabel,
  SelectGroup,
  SelectContent,
  SelectValue,
  SelectTrigger,
  SelectSeparator,
} from "@/core/components/third-party/ui/select";
import { SearchBar } from "./Searchbar";
import dayjs from "dayjs";
import { GlobalState, TransformedCompany } from "../common/state/machine";
import { SectorFilterPreview } from "./SectorFilterPreview";
import { PaginationCardMinimalCenterAligned } from "@/core/components/molecules/pagination/PagninationCardMinimalCentrerAligned";
import { Button } from "@/core/components/third-party/ui/button";
import { Loader2 } from "lucide-react";
import { cn } from "@/lib/utils";
import { NoResultsFound } from "./NoResultsFound";

export function ResultsStack() {
  const globalState = GlobalState.machine.useState();

  function handleAddToPeerset(company: TransformedCompany) {
    globalState.mutable.addToPeerset(company);
  }

  function handleSortChange(value: string) {
    if (value === "company_name (A-Z)") {
      globalState.mutable.setSortField("company_name");
      globalState.mutable.setSortDirection("asc");
    } else if (value === "company_name (Z-A)") {
      globalState.mutable.setSortField("company_name");
      globalState.mutable.setSortDirection("desc");
    } else if (value === "nss-high-to-low") {
      globalState.mutable.setSortField("nss_per_company");
      globalState.mutable.setSortDirection("desc");
    } else if (value === "nss-low-to-high") {
      globalState.mutable.setSortField("nss_per_company");
      globalState.mutable.setSortDirection("asc");
    } else if (value === "review_count-high-to-low") {
      globalState.mutable.setSortField("count_per_company");
      globalState.mutable.setSortDirection("desc");
    } else if (value === "review_count-low-to-high") {
      globalState.mutable.setSortField("count_per_company");
      globalState.mutable.setSortDirection("asc");
    } else if (value === "rating-high-to-low") {
      globalState.mutable.setSortField("rating_per_company");
      globalState.mutable.setSortDirection("desc");
    } else if (value === "rating-low-to-high") {
      globalState.mutable.setSortField("rating_per_company");
      globalState.mutable.setSortDirection("asc");
    }
  }

  function handlePageGoBack() {
    globalState.mutable.decrementPage();
  }

  function handlePageGoForward() {
    globalState.mutable.incrementPage();
  }

  function handlePageGoStart() {
    globalState.mutable.decrementToStart();
  }

  function handlePageGoEnd() {
    globalState.mutable.incrementToEnd();
  }

  return (
    <div className="flex flex-col gap-2 pt-0 w-full" data-testid="results-stack-container">
      <div className="flex items-center justify-between gap-2" data-testid="search-controls">
        <SearchBar />
        <Select onValueChange={handleSortChange}>
          <SelectTrigger className="w-[280px] ">
            <SelectValue placeholder="Sort by" prefix="Sort by: " />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Company Name</SelectLabel>
              <SelectItem value="company_name (A-Z)">Alphabetically (A-Z)</SelectItem>
              <SelectItem value="company_name (Z-A)">Alphabetically (Z-A)</SelectItem>
            </SelectGroup>

            <SelectSeparator />

            <SelectGroup>
              <SelectLabel>NSS Score</SelectLabel>
              <SelectItem value="nss-high-to-low">NSS: High to Low</SelectItem>
              <SelectItem value="nss-low-to-high">NSS: Low to High</SelectItem>
            </SelectGroup>

            <SelectSeparator />

            <SelectGroup>
              <SelectLabel>Review Count</SelectLabel>
              <SelectItem value="review_count-high-to-low">Review Count: High to Low</SelectItem>
              <SelectItem value="review_count-low-to-high">Review Count: Low to High</SelectItem>
            </SelectGroup>

            <SelectSeparator />

            <SelectGroup>
              <SelectLabel>Rating</SelectLabel>
              <SelectItem value="rating-high-to-low">Rating: High to Low</SelectItem>
              <SelectItem value="rating-low-to-high">Rating: Low to High</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <SectorFilterPreview />
      {globalState.readonly.companiesIsPending ? (
        Array.from({ length: globalState.readonly.page_size }).map((_, index) => <CardSkeleton key={index} />)
      ) : globalState.readonly.companies.length === 0 ? (
        <NoResultsFound />
      ) : (
        Object.values(globalState.readonly.companies).map((company) => {
          let scoreString = "";
          if (
            globalState.readonly.sort_field === "nss_per_company" ||
            globalState.readonly.sort_field === "company_name"
          ) {
            scoreString = `NSS | ${company.nss_per_company?.sentiment === "pos" ? "Positive" : company.nss_per_company?.sentiment === "neg" ? "Negative" : "Neutral"} | ${company.nss_per_company?.percentage_string}`;
          } else if (globalState.readonly.sort_field === "count_per_company") {
            scoreString = `Review Count | ${company.count_per_company.strength === "low" ? "Low" : company.count_per_company.strength === "ok" ? "OK" : ""} | ${company.count_per_company?.compact_number}`;
          } else if (globalState.readonly.sort_field === "rating_per_company") {
            scoreString = `Rating | ${company.rating_per_company?.rating}`;
          }
          return (
            <Card
              key={company.company_id}
              company_name={company.company_name}
              description={company.company_description}
              primary_sectors={company.sectors.primary.map((sector) => {
                return {
                  name: sector.sector_name,
                  description: sector.sector_rationale,
                };
              })}
              avatar={company.company_logo_src}
              nss={{
                sentiment: company.nss_per_company?.sentiment,
                sentiment_score: company.nss_per_company?.percentage_string,
              }}
              score={scoreString}
              captured_at={dayjs(company.review_data_last_capture_date).format("MMM/YYYY")}
              firmographics={[
                `Employees: ${company.company_headcount?.compact_number ?? "Unknown"}`,
                `HQ: ${company.company_headquarters ?? "Unknown"}`,
              ]}
              onClick={() => handleAddToPeerset(company as TransformedCompany)}
            />
          );
        })
      )}
      <div className="flex items-center justify-between gap-2 pt-2" data-testid="pagination-controls">
        <div className="flex items-center gap-2" data-testid="pagination-left-controls">
          <Button variant="outline" onClick={handlePageGoStart} disabled={globalState.readonly.current_page === 1}>
            Start
          </Button>
          <Button
            variant="outline"
            onClick={handlePageGoForward}
            disabled={globalState.readonly.current_page === globalState.readonly.total_pages}
          >
            Next
          </Button>
        </div>
        {globalState.readonly.companiesIsPending ? (
          <Loader2 className="h-5 w-5 text-gray-700 animate-spin" data-testid="pagination-loader" />
        ) : (
          <span
            className={cn("text-muted-foreground font-text-sm-medium")}
            data-testid="pagination-info"
          >{`${globalState.readonly.current_page} of ${globalState.readonly.total_pages}`}</span>
        )}
        <div className="flex items-center gap-2" data-testid="pagination-right-controls">
          <Button variant="outline" onClick={handlePageGoBack} disabled={globalState.readonly.current_page === 1}>
            Prev
          </Button>
          <Button
            variant="outline"
            onClick={handlePageGoEnd}
            disabled={globalState.readonly.current_page === globalState.readonly.total_pages}
          >
            End
          </Button>
        </div>
      </div>
    </div>
  );
}
