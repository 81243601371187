import { useQueryMetadata } from "./useQueryMetadata";

export function Injector({ children }: { children: React.ReactNode }) {
  useQueryMetadata();
  return <>{children}</>;
}

export const QueryLayer = {
  Injector,
};
