import { StateInstance } from "../types";
import pptxgen from "pptxgenjs";
import JSZip from "jszip";
import { notifyUser } from "@/core/notifications/notify";
const eventFactory = {
  addToExportables: (instance: StateInstance) => {
    return (payload: { instance_id: string }) => {
      if (payload.instance_id in instance.state.export.data) return;
      instance.state.export.data[payload.instance_id] = {
        pptx_data: [],
        csv_data: {
          folder_name: "",
          file_data: [],
        },
      };
    };
  },

  removeFromExportables: (instance: StateInstance) => {
    return (payload: { instance_id: string }) => {
      delete instance.state.export.data[payload.instance_id];
      if (Object.keys(instance.state.export.data).length === 0) {
        instance.state.view = "read";
      }
    };
  },

  selectAllForExport: (instance: StateInstance) => {
    return () => {
      Object.keys(instance.state.components).forEach((instance_id) => {
        if (instance_id in instance.state.export.data) return;
        instance.state.export.data[instance_id] = {
          pptx_data: [],
          csv_data: {
            folder_name: "",
            file_data: [],
          },
        };
      });
    };
  },

  exportReport: (instance: StateInstance) => {
    return async () => {
      const zip = new JSZip();
      const masterFolder = zip.folder("master");
      const pptx = new pptxgen();
      let pptx_count = 0;
      Object.entries(instance.state.export.data).forEach(([instance_id, payload]) => {
        payload.pptx_data.forEach((pptxConfig) => {
          pptx_count++;
          const slide = pptx.addSlide();
          slide.addChart(pptxConfig.type, pptxConfig.data, pptxConfig.options);
        });
        const folder = masterFolder?.folder(payload.csv_data.folder_name);
        payload.csv_data.file_data.forEach((file) => {
          folder?.file(file.filename, file.data);
        });
      });

      if (pptx_count > 0) {
        const pptxBlob = await pptx.write({ outputType: "blob" });
        masterFolder?.file("analysis-powerpoint-deck.pptx", pptxBlob);
      }

      const zipContent = await zip.generateAsync({ type: "blob" });
      const url = URL.createObjectURL(zipContent);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "culture-playground-report.zip");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      instance.state.view = "read";
      instance.state.export.data = {};
      notifyUser.defaultSuccess("Report exported successfully");
    };
  },

  createEvents: (instance: StateInstance) => {
    return {
      addToExportables: eventFactory.addToExportables(instance),
      removeFromExportables: eventFactory.removeFromExportables(instance),
      selectAllForExport: eventFactory.selectAllForExport(instance),
      exportReport: eventFactory.exportReport(instance),
    };
  },
};

export { eventFactory as commonEventFactory };
