import { createValtioContextProvider } from "@/core/utils/valtio/core";
import { Company, Sector } from "../endpoints/companies";
import { useCallback } from "react";
import { Metadata } from "../endpoints/metadata";
import { notifyUser } from "@/core/notifications/notify";

const MACHINE_NAME = "MY-COMPANIES-GLOBAL-STATE";

export interface TransformedCompany
  extends Omit<
    Company,
    | "sectors"
    | "nss_per_company"
    | "prevalence_per_company"
    | "count_per_company"
    | "rating_per_company"
    | "company_headcount"
  > {
  nss_per_company: {
    percentage: number | null;
    percentage_string: string | null;
    strength: "high" | "medium" | "low" | "neutral" | null;
    sentiment: "pos" | "neg" | "neutral" | null;
  };
  prevalence_per_company: {
    percentage: number | null;
    percentage_string: string | null;
  };
  count_per_company: {
    count: number | null;
    compact_number: string | null;
    strength: "low" | "ok" | null;
  };
  rating_per_company: {
    rating: number | null;
  };
  sectors: {
    primary: Sector[];
    secondary: Sector[];
  };
  company_headcount: {
    compact_number: string | null;
  };
  signal_strength: "SIGNAL" | "LOW_SIGNAL" | "NO_SIGNAL";
}

export class GlobalStateMachine {
  control_panel: {
    inView: "peerset" | "company_info" | "year" | null;
    companyInfo?: {
      company: TransformedCompany;
    };
  };
  total_count: number;
  company_id?: string[];
  company_name?: string;
  sector?: Record<string, string>;
  page_start: number;
  page_end: number;
  page_size: number;
  sort_field?: keyof Company;
  sort_direction?: "asc" | "desc";
  // 👇 By Main Sector -> By Company Id
  peerset: Record<string, TransformedCompany>;
  current_page: number;
  total_pages: number;
  companies: TransformedCompany[];
  companiesIsPending: boolean;
  maxPeersetSlots: number;
  remainingPeersetSlots: number;
  metadata: Record<string, Metadata[]>;
  metadataIsPending: boolean;
  error: boolean;

  constructor() {
    const state = JSON.parse(localStorage.getItem(MACHINE_NAME) ?? "{}") as {
      peerset: Record<string, TransformedCompany>;
    };
    this.total_count = 0;
    this.page_size = 20;
    this.page_start = 0;
    this.page_end = this.page_start + this.page_size;
    this.company_id = [];
    this.company_name = "";
    this.sector = {};
    this.peerset = state.peerset ?? {};
    this.current_page = 1;
    this.total_pages = Math.ceil(this.total_count / this.page_size);
    this.companies = [];
    this.companiesIsPending = false;
    this.maxPeersetSlots = 15;
    this.remainingPeersetSlots = this.maxPeersetSlots;
    this.control_panel = {
      inView: null,
    };
    this.sort_field = "company_name";
    this.sort_direction = "asc";
    this.metadata = {};
    this.metadataIsPending = false;
    this.error = false;
  }

  // Pagination
  incrementPage() {
    if (this.current_page < this.total_pages) {
      window.scrollTo(0, 0);
      this.current_page += 1;
      this.page_start = (this.current_page - 1) * this.page_size;
      this.page_end = this.page_start + this.page_size;
    }
  }

  decrementPage() {
    if (this.current_page > 1) {
      window.scrollTo(0, 0);
      this.current_page -= 1;
      this.page_start = (this.current_page - 1) * this.page_size;
      this.page_end = this.page_start + this.page_size;
    }
  }

  decrementToStart() {
    window.scrollTo(0, 0);
    this.current_page = 1;
    this.page_start = 0;
    this.page_end = this.page_start + this.page_size;
  }

  incrementToEnd() {
    window.scrollTo(0, 0);
    this.current_page = this.total_pages;
    this.page_start = (this.current_page - 1) * this.page_size;
    this.page_end = this.page_start + this.page_size;
  }

  // Total Count
  setTotalCount(count: number) {
    this.total_count = count;
    this.total_pages = Math.ceil(this.total_count / this.page_size);
  }

  setCompanyName(value: string) {
    this.company_name = value;
    this.decrementToStart();
  }

  setCompanies(companies: TransformedCompany[]) {
    this.companies = companies;
  }

  setCompaniesIsPending(isPending: boolean) {
    this.companiesIsPending = isPending;
  }

  private getPeersetSlotCount() {
    return Object.keys(this.peerset).length;
  }

  addToPeerset(company: TransformedCompany) {
    const companyId = company.company_id;
    if (this.getPeersetSlotCount() >= this.maxPeersetSlots) {
      // TODO: Error Toast notification
      return;
    }

    // Check if company already exists in any sector
    const exists = this.peerset[companyId];
    if (exists !== undefined && exists !== null) {
      notifyUser.defaultMessage("Company already exists in peerset");
      return;
    }

    this.peerset[companyId] = company;
    this.remainingPeersetSlots = this.maxPeersetSlots - this.getPeersetSlotCount();
    notifyUser.companyMessage({ company, suffix: "added to peerset", color: "success" });
  }

  removeFromPeerset(companyId: string) {
    const companyToRemove = this.peerset[companyId];
    if (companyToRemove) {
      delete this.peerset[companyId];
      this.remainingPeersetSlots = this.maxPeersetSlots - this.getPeersetSlotCount();
      notifyUser.companyMessage({ company: companyToRemove, suffix: "removed from peerset", color: "error" });
      this.remainingPeersetSlots = this.maxPeersetSlots - this.getPeersetSlotCount();
    }
  }

  clearPeerset() {
    this.peerset = {};
    this.remainingPeersetSlots = this.maxPeersetSlots;
  }

  openCompanyInfoControlPanel(company: TransformedCompany) {
    this.control_panel.companyInfo = { company };
    this.control_panel.inView = "company_info";
  }

  closeCompanyInfoControlPanel() {
    this.control_panel.companyInfo = undefined;
    this.control_panel.inView = null;
  }

  closeAllControlPanels() {
    this.control_panel.companyInfo = undefined;
    this.control_panel.inView = null;
  }

  setControlPanelInView(panel: "peerset" | "company_info" | "year" | null) {
    this.control_panel.inView = panel;
  }

  // Sort
  setSortField(field: keyof Company) {
    this.decrementToStart();
    this.sort_field = field;
  }

  setSortDirection(direction: "asc" | "desc") {
    this.sort_direction = direction;
  }

  // Sector Filter
  toggleSectorFilter(key: string) {
    if (!this.sector) {
      this.sector = {};
    }
    if (this.sector[key]) {
      delete this.sector[key];
    } else {
      this.sector[key] = key;
    }
    this.decrementToStart();
  }

  clearAllSectorFilters() {
    this.sector = {};
  }

  setMetadata(metadata: Record<string, Metadata[]>) {
    this.metadata = metadata;
  }

  setMetadataIsPending(isPending: boolean) {
    this.metadataIsPending = isPending;
  }

  setError(error: boolean) {
    this.error = error;
  }
}

const machine = createValtioContextProvider<GlobalStateMachine>(MACHINE_NAME);

function useIsCompanyInPeerset() {
  const globalState = machine.useState();
  return useCallback(
    (company: TransformedCompany) => {
      // const mainSector = company.sectors.primary?.sector_name;
      // const companyId = company.company_id;
      // if (!mainSector || !companyId) return false;
      // return globalState.readonly.peerset[mainSector]?.[companyId] ? true : false;
      return false;
    },
    [globalState.readonly.peerset],
  );
}

function useGetPeersetSlotCount() {
  const globalState = machine.useState();
  return globalState.readonly.maxPeersetSlots - globalState.readonly.remainingPeersetSlots;
}

function useIsSectorFilterSelected() {
  const globalState = machine.useState();
  return useCallback(
    (key: string) => {
      return globalState.readonly.sector?.[key] ? true : false;
    },
    [globalState.readonly.sector],
  );
}

function useGetPeersetCompanyIds() {
  const globalState = machine.useState();
  return useCallback(() => {
    return Object.keys(globalState.readonly.peerset).flatMap((mainSector) =>
      Object.keys(globalState.readonly.peerset[mainSector]),
    );
  }, [globalState.readonly.peerset]);
}

function useGetMetadataByType() {
  const globalState = machine.useState();
  return useCallback(
    (type: string) => {
      const result = globalState.readonly.metadata[type];
      if (!result) return [] as Metadata[];
      return result as Metadata[];
    },
    [globalState.readonly.metadata],
  );
}

function useGetIsSectorsEmpty() {
  const globalState = machine.useState();
  return useCallback(() => {
    return Object.keys(globalState.readonly.sector ?? {}).length === 0;
  }, [globalState.readonly.sector]);
}

export const GlobalState = {
  name: MACHINE_NAME,
  machine,
  hooks: {
    useIsCompanyInPeerset,
    useGetPeersetSlotCount,
    useIsSectorFilterSelected,
    useGetPeersetCompanyIds,
    useGetMetadataByType,
    useGetIsSectorsEmpty,
  },
};
