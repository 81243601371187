import { createRenderStableProxyContextInjector } from "@/core/utils/valtio/core";
import { CaptureError } from "@/features/data-components/culture/company-personalities/_common/state/types";
import { proxy } from "valtio";
import { IState, StateInstance, IComponent } from "./types";
import { commonEventFactory } from "./event-layer/common";

const FACTORY_ID = "REPORT_FACTORY";

interface FactoryProps {
  id: string;
  name: string;
  captureError?: CaptureError;
  components?: Record<string, IComponent>;
}

export const stateFactory = {
  createState: (props: FactoryProps): IState => {
    return proxy({
      instance_id: props.id,
      name: props.name,
      view: "read",
      metadata: {
        company: { pending: false, error: false, data: {} },
      },
      export: {
        pending: false,
        error: false,
        data: {},
      },
      components: props.components || {},
    });
  },
  createEvents: (instance: StateInstance) => {
    return {
      common: commonEventFactory.createEvents(instance),
    };
  },
  createSubscriptions: (instance: StateInstance) => {
    type Subscribers = (() => void)[];
    const subscribers: Subscribers = [];
    return {
      destroy: () => {
        subscribers.forEach((unsubscribe) => unsubscribe());
      },
    };
  },

  createInstance: (props: FactoryProps) => {
    const state = stateFactory.createState(props);
    const instance: StateInstance = { state, captureError: props.captureError };

    const events = stateFactory.createEvents(instance);
    stateFactory.createSubscriptions(instance);

    return {
      state,
      events,
    };
  },
};

type Factory = ReturnType<typeof stateFactory.createInstance>;
export const StateLayer = createRenderStableProxyContextInjector<Factory>(FACTORY_ID);
