import { TooltipContainer } from "@/core/components/atoms/tooltips/TooltipContainer";
import { Avatar, AvatarFallback, AvatarImage } from "@/core/components/third-party/ui/avatar";
import { Badge } from "@/core/components/third-party/ui/badge";
import { cn } from "@/core/lib/utils";
import { Ban } from "lucide-react";
import { ThumbsUp } from "lucide-react";
import { ThumbsDown } from "lucide-react";
import { GlobalState, TransformedCompany } from "../common/state/machine";
import { CharacterLimiter } from "@/core/components/atoms/text/CharacterLimiter";
import { Skeleton } from "@/core/components/third-party/ui/skeleton";

const dummyData = {
  id: "1",
  name: "Acme Corporation",
  read: false,
  date: new Date("2024-01-15"),
  subject: "Annual Financial Report",
  text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  labels: ["Finance", "Annual Report"],
};

interface IProps {
  company_id?: string;
  company_name?: string;
  primary_sectors?: { name: string; description: string }[];
  secondary_sectors?: string[];
  avatar?: string;
  description?: string;
  nss?: {
    sentiment?: "pos" | "neg" | "neutral" | null;
    sentiment_score?: string | null;
  };
  score?: string | null;
  captured_at?: string;
  firmographics?: string[];
  onClick?: () => void;
}

export function Card(props: IProps) {
  return (
    <button
      data-testid="company-card"
      key={dummyData.id}
      className={cn(
        "flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent/45",
        false && "bg-muted",
      )}
      onClick={props.onClick}
    >
      <div className="flex w-full flex-col gap-1">
        <div className="flex items-center">
          <Avatar className="h-8 w-8 rounded-full border border-gray-200 mr-2">
            <AvatarImage src={props.avatar} alt={props.company_name} />
            <AvatarFallback className="rounded-lg">CN</AvatarFallback>
          </Avatar>
          <div className="flex items-center gap-2">
            <div data-testid="company-name" className="font-semibold">
              {props.company_name}
            </div>
          </div>
          <div
            data-testid="company-score"
            className={cn(
              "ml-auto text-xs flex items-center gap-1",
              false ? "text-foreground" : "text-muted-foreground",
            )}
          >
            {props.score ? <span>{props.score}</span> : null}
          </div>
        </div>
        <div data-testid="company-capture-date" className="text-xs font-medium">
          Captured at: {props.captured_at || "Unknown"}
        </div>
      </div>
      <CharacterLimiter
        characterLimit={300}
        classNames={{
          text: "text-xs text-muted-foreground",
        }}
      >
        {props.description ?? "No description available"}
      </CharacterLimiter>
      {/* <div className="line-clamp-2 text-xs text-muted-foreground"></div> */}
      {props.primary_sectors?.length ? (
        <div data-testid="company-sectors" className="flex items-center gap-2 flex-wrap">
          {props.primary_sectors.map((label) => (
            <TooltipContainer
              side="right"
              title={`Primary: ${label.name} sector`}
              displaySupportingText
              supportingText={<span>{label.description}</span>}
              trigger={
                <Badge key={label.name} variant="outline">
                  {label.name}
                </Badge>
              }
            />
          ))}
          {props.secondary_sectors?.length ? (
            <TooltipContainer
              title={"Secondary Sectors"}
              side="right"
              displaySupportingText
              supportingText={
                <div className="flex flex-wrap gap-1">
                  {props.secondary_sectors?.map((label, idx) => (
                    <span key={label}>
                      {label}
                      {idx === (props.secondary_sectors?.length || 0) - 1 ? "" : ","}
                    </span>
                  ))}
                </div>
              }
              trigger={
                <Badge variant="outline">
                  {props.secondary_sectors?.length === 0
                    ? ""
                    : `${props.secondary_sectors?.length === 1 ? "" : "+"}${props.secondary_sectors?.length} secondary sectors`}
                </Badge>
              }
            />
          ) : null}
          {props.firmographics?.map((firmographic) => (
            <Badge key={firmographic} variant="outline">
              {firmographic}
            </Badge>
          ))}
        </div>
      ) : null}
    </button>
  );
}

export function CardSkeleton() {
  return (
    <div
      data-testid="company-card-skeleton"
      className="flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm"
    >
      <div className="flex w-full flex-col gap-1">
        <div className="flex items-center">
          <Skeleton className="h-8 w-8 rounded-full mr-2" />
          <Skeleton className="h-5 w-32" />
          <Skeleton className="h-4 w-16 ml-auto" />
        </div>
        <Skeleton className="h-4 w-40" />
      </div>
      <Skeleton className="h-12 w-full" />
      <div className="flex items-center gap-2">
        <Skeleton className="h-6 w-20" />
        <Skeleton className="h-6 w-20" />
        <Skeleton className="h-6 w-20" />
      </div>
    </div>
  );
}
