import ReactDOM from "react-dom/client";
import { AppRoot } from "./AppRoot";
import "./main.css";
import "@/core/config/env";
import "./Sentry.init";

const rootElement = document.getElementById("app")!;

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<AppRoot />);
}
