/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { cva } from "class-variance-authority";
import type {
  ClassProp,
  ClassValue,
  StringToBoolean,
} from "class-variance-authority/types";
import _ from "lodash";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * A type representing the schema of the configuration. The keys are strings, and the values are records
 * where the keys are strings and the values are ClassValue.
 */
type ConfigSchema = Record<string, Record<string, ClassValue>>;

/**
 * A type representing the possible variants for a given configuration schema. Each key in the configuration
 * schema can have a value that is either a boolean string, null, or undefined.
 */
type ConfigVariants<T extends ConfigSchema> = {
  [Variant in keyof T]?: StringToBoolean<keyof T[Variant]> | null | undefined;
};

/**
 * A type representing the possible variants for a given configuration schema that allows multiple values.
 * Each key in the configuration schema can have a value that is either a boolean string, an array of boolean strings, or undefined.
 */
type ConfigVariantsMulti<T extends ConfigSchema> = {
  [Variant in keyof T]?:
    | StringToBoolean<keyof T[Variant]>
    | StringToBoolean<keyof T[Variant]>[]
    | undefined;
};

/**
 * A type representing the configuration for the CVA class. It includes optional variants, default variants,
 * and compound variants.
 */
type Config<T> = T extends ConfigSchema
  ? {
      variants?: Partial<T>;
      defaultVariants?: ConfigVariants<T>;
      compoundVariants?: (T extends ConfigSchema
        ? (ConfigVariants<T> | ConfigVariantsMulti<T>) & ClassProp
        : ClassProp)[];
    }
  : never;

/**
 * A type representing the properties that can be passed to the CVA create method. It includes the variants
 * and additional class properties.
 */
export type VariantProps<T> = T extends ConfigSchema
  ? ConfigVariants<T> & ClassProp
  : ClassProp;

/**
 * A type representing the function that is returned by the create method.
 * It takes properties of type Props<Variants> and returns a string.
 */
export type DynamicStyle<Variants extends ConfigSchema> = (
  props: VariantProps<Variants>,
  override?: string,
) => string;

/**
 *  * A class for managing configuration variants and applying them to class names using the class-variance-authority library.
 *  * Variants - The schema of the configuration variants.
 *
 * Example of using the CVA class
 *
 * ```typescript
 * import { CVA, type DynamicStyle } from "@/lib/cva";
 *
 * export type Variants = {
 *   size: {
 *     xs: string;
 *     lg: string;
 *   };
 * };
 *
 * interface IStyles {
 *   section: {
 *     styles: DynamicStyle<Variants>;
 *     container: {
 *       styles: DynamicStyle<Variants>;
 *       logo: DynamicStyle<Variants>;
 *       menu: DynamicStyle<Variants>;
 *     };
 *   };
 * }
 *
 * const cva = new CVA<Variants>({
 *   defaultVariants: {
 *     size: "xs",
 *   },
 * });
 *
 * export const styles: IStyles = {
 *   section: {
 *     styles: cva.create("absolute top-0 w-full", {
 *       variants: {
 *         size: {
 *           xs: "px-4",
 *           lg: "",
 *         },
 *       },
 *     }),
 *     container: {
 *       styles: cva.create(
 *         "rounded-full bg-gray-100 flex items-center justify-between w-full",
 *         {
 *           variants: {
 *             size: {
 *               xs: "px-5 py-4",
 *               lg: "",
 *             },
 *           },
 *         },
 *       ),
 *       logo: cva.create("", {}),
 *       menu: cva.create("", {}),
 *     },
 *   },
 * };
 * ```
 */
export class CVA<Variants extends ConfigSchema> {
  /**
   * The configuration for the CVA instance.
   * @type {Config<Variants>}
   */
  private config: Config<Variants>;

  /**
   * Creates an instance of CVA.
   * @param {Config<Variants>} config - The configuration for the CVA instance.
   */
  constructor(config: Config<Variants>) {
    this.config = config;
  }

  /**
   * Creates a function that can be used to apply the configuration variants to class names.
   * @param {string} defaults - The default class names to apply.
   * @param {Config<Variants>} config - The configuration to merge with the instance configuration.
   * @returns {(props: Props<Variants>) => string} A function that takes properties and returns the resulting class names.
   */
  create(defaults: string, config: Config<Variants>): DynamicStyle<Variants> {
    return (props: VariantProps<Variants>, override?: string) => {
      const mergedConfig = _.merge(config, this.config);
      return cn(
        cva(defaults, {
          variants: mergedConfig.variants as any,
          defaultVariants: mergedConfig.defaultVariants,
          compoundVariants: mergedConfig.compoundVariants,
        })(props),
        override,
      );
    };
  }
}

// Tailwind additional utilities
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export interface ICnaOptions {
  overwrite?: boolean;
}
export function cna(
  options: ICnaOptions = { overwrite: false },
  ...inputs: ClassValue[]
) {
  // Check if the overwrite option is true
  if (options?.overwrite) {
    // Return only the last class value, effectively ignoring all others
    return clsx(inputs[inputs.length - 1]);
  } else {
    // If not overwriting, merge all class values as before
    return twMerge(clsx(inputs));
  }
}
