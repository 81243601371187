import { useEffect } from "react";
import { useCallback } from "react";
import { Auth } from "../auth/machine";
import { createValtioContextProvider } from "../utils/valtio/core";
import { ClientError } from "./types";
import * as Sentry from "@sentry/react";

export class ErrorMachine {
  capture(error: ClientError) {
    Sentry.captureException(error);
  }

  setUser(user: { email: string; name: string }) {
    Sentry.setUser({
      email: user?.email,
      name: user?.name,
    });
  }
}

const Machine = createValtioContextProvider<ErrorMachine>("ErrorMachine");

function Injector({ children }: { children: React.ReactNode }) {
  return <Machine.Injector value={new ErrorMachine()}>{children}</Machine.Injector>;
}

function useCaptureError() {
  const state = Error.Machine.useState();

  const capture = useCallback((error: ClientError) => {
    state.mutable.capture(error);
  }, []);

  return capture;
}

function useEstablishUser() {
  const auth = Auth.Machine.useState();
  const state = Error.Machine.useState();

  useEffect(() => {
    state.mutable.setUser({
      email: auth?.readonly?.user?.email ?? "",
      name: auth?.readonly?.user?.name ?? "",
    });
  }, [auth?.readonly?.user]);
}

export const Error = { Machine, Injector, hooks: { useCaptureError, useEstablishUser } };
