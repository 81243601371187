/**
 * @component
 * @description Renders a component to display when a 404 Not Found error occurs.
 *
 * @remarks
 * The 404 Not Found error is a standard HTTP response code indicating that the
 * client was able to communicate with the server, but the server could not find
 * the requested resource. This component provides a simple visual representation
 * of this error to the user.
 *
 * @returns {JSX.Element} A div element containing the text "Error 404"
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/404 | HTTP 404 status code}
 */
export function Error404Component() {
  return <div>Error 404</div>;
}
