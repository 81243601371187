import { Badge } from "@/core/components/third-party/ui/badge";
import { GlobalState } from "../common/state/machine";
import { useState } from "react";
import { cn } from "@/core/utils/tailwind-toolkit/core";
import { Loader2 } from "lucide-react";

export function SectorFilterPreview() {
  const globalState = GlobalState.machine.useState();
  const metadataByType = GlobalState.hooks.useGetMetadataByType();
  const sectorMetadata = metadataByType("sector");
  const [expanded, setExpanded] = useState(false);

  // console.log(sectorMetadata);

  const isSectorFilterSelected = GlobalState.hooks.useIsSectorFilterSelected();
  const isSectorsEmpty = GlobalState.hooks.useGetIsSectorsEmpty();

  function handleToggleSectorFilter(key: string) {
    globalState.mutable.toggleSectorFilter(key);
  }

  function handleExpand() {
    setExpanded(!expanded);
  }

  function handleSelectAllSectors() {
    globalState.mutable.clearAllSectorFilters();
  }

  if (globalState.readonly.metadataIsPending) {
    return (
      <div className="w-full flex-wrap flex gap-[6px] my-2" data-testid="loading-sectors">
        <Badge variant="outline" className="cursor-pointer w-fit gap-1 pl-1">
          <Loader2 className="animate-spin size-4" />
          Loading Sectors
        </Badge>
      </div>
    );
  }

  return (
    <div className="w-full flex-wrap flex gap-[6px] my-2" data-testid="sector-filter-preview">
      <Badge
        variant={isSectorsEmpty() ? "default" : "outline"}
        className="cursor-pointer "
        onClick={handleSelectAllSectors}
        data-testid="all-sectors-badge"
      >
        All Sectors
      </Badge>
      {sectorMetadata
        .slice()
        .sort((a, b) => a.meta_data_value.localeCompare(b.meta_data_value))
        .slice(0, expanded ? sectorMetadata.length : 8)
        .map((metadata) => {
          return (
            <Badge
              key={metadata.meta_data_value}
              variant={isSectorFilterSelected(metadata.meta_data_value) ? "default" : "outline"}
              className={cn(
                "cursor-pointer",
                isSectorFilterSelected(metadata.meta_data_value) ? "" : " hover:bg-muted",
              )}
              onClick={() => handleToggleSectorFilter(metadata.meta_data_value)}
              data-testid={`sector-badge-${metadata.meta_data_value}`}
            >
              {metadata.meta_data_value}
            </Badge>
          );
        })}
      <Badge
        variant="outline"
        onClick={handleExpand}
        className="cursor-pointer hover:bg-muted"
        data-testid="expand-toggle"
      >
        {expanded ? "Show less" : `+ ${sectorMetadata.length - 8} more`}
      </Badge>
    </div>
  );
}
