import { Loader2 } from "lucide-react";
import React from "react";
import { Toaster, toast, ExternalToast } from "sonner";

export interface PublishProps {
  message: string | React.ReactNode;
  data: ExternalToast;
}

class Notification {
  error(props: PublishProps) {
    toast.error(props.message, {
      unstyled: false,
      ...props.data,
    });
  }
  success(props: PublishProps) {
    toast.success(props.message, {
      unstyled: false,
      ...props.data,
    });
  }
  warning(props: PublishProps) {
    toast.warning(props.message, {
      unstyled: false,
      ...props.data,
    });
  }
  info(props: PublishProps) {
    toast.info(props.message, {
      unstyled: false,
      ...props.data,
    });
  }
  pending(props: PublishProps) {
    toast(props.message, {
      unstyled: false,
      icon: <Loader2 className="animate-spin" />,
      ...props.data,
    });
  }
}

export const notification = new Notification();

//dependency injection
export function Injector() {
  return (
    <Toaster
      expand
      visibleToasts={2}
      position="top-center"
      richColors
      toastOptions={{
        unstyled: false,
      }}
    />
  );
}
