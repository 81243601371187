import { PageHeaderSimpleContainer } from "@/core/components/molecules/page-headers/PageHeadersSimple";
import { Separator } from "@/core/components/third-party/ui/separator";
import { SidebarTrigger } from "@/core/components/third-party/ui/sidebar";
import {
  Breadcrumb,
  BreadcrumbSeparator,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbLink,
} from "@/core/components/third-party/ui/breadcrumb";
import { DesktopControlPanel } from "./control-panel/DesktopControlPanel";
import { Suspense, lazy } from "react";
import { StateLayer } from "../_common/state/state-layer";
import { getDataComponent, DataComponents } from "../../data-components";

export function Report() {
  const state = StateLayer.useState();
  return (
    <div className="flex bg-sidebar dark:bg-transparent">
      <div className="w-full flex flex-col gap-3">
        <header className="flex px-4 h-16 shrink-0 items-center gap-2">
          <div className="flex items-center gap-2">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbSeparator className="hidden md:block" />
                <BreadcrumbItem>
                  <BreadcrumbLink>Report</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator className="hidden md:block" />
                <BreadcrumbItem>
                  <BreadcrumbPage>Culture Playground</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </div>
        </header>
        <div className="flex px-4 items-start gap-6 justify-between w-full">
          <div className="flex flex-col gap-5">
            <PageHeaderSimpleContainer className="max-w-[700px] w-full">
              <PageHeaderSimpleContainer.TitleSection>
                <PageHeaderSimpleContainer.Title>Culture Playground</PageHeaderSimpleContainer.Title>
              </PageHeaderSimpleContainer.TitleSection>
            </PageHeaderSimpleContainer>
          </div>
        </div>
        <div className="w-full pt-2 px-4 pb-6 gap-4 flex flex-col">
          <Suspense
            fallback={
              <div className="w-full p-6 border rounded-lg">
                <div className="flex items-center justify-center gap-3">
                  <div className="size-5 border-2 border-muted-foreground border-t-foreground rounded-full animate-spin" />
                  <span className="text-sm text-muted-foreground">Loading report components...</span>
                </div>
              </div>
            }
          >
            {Object.entries(state.readonly.state.components).map(([instance_id, item], index) => {
              const DataComponent = getDataComponent({ id: item.type, options: { throwError: false } });
              if (!DataComponent?.Component) return null;
              // TODO: Pass props
              return (
                <DataComponent.Component
                  key={index}
                  instance_id={instance_id}
                  name={item.name}
                  preferences={item.preferences}
                />
              );
            })}
          </Suspense>
        </div>
      </div>
      <DesktopControlPanel />
    </div>
  );
}
