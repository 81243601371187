import { GlobalStateMachine, GlobalState } from "./machine";

interface IProps {
  children?: React.ReactNode;
}
export function StateInjector(props: IProps) {
  return (
    <GlobalState.machine.Injector
      value={new GlobalStateMachine()}
      subscriber={(machine) => {
        const state = {
          peerset: machine.peerset,
        };
        localStorage.setItem(GlobalState.name, JSON.stringify(state));
      }}
    >
      {props.children}
    </GlobalState.machine.Injector>
  );
}
