import { CacheQueryNotification } from "@/core/cache/core";
import { ClientError, Error } from "@/core/errors";
import { http } from "@/core/http/core";
import { notification } from "@/core/notifications/core";

export const cacheKey = "companies" as const;

interface Sector {
  sector_name: string;
  sector_is_primary: boolean;
  sector_rationale: string;
}

export interface Company {
  company_id: string;
  company_name: string;
  sector: string;
  company_description: string;
  company_url: string;
  ceo: string;
  company_headquarters: string;
  company_headcount: number;
  company_logo_url: string;
  company_logo_src: string;
  also_known_as: string;
  meta_data_is_ready: boolean;
  review_data_is_ready: boolean;
  review_data_last_capture_date: string;
  onboarding_notes: string;
  sectors: Sector[];
  nss_per_company: string;
  prevalence_per_company: string;
  count_per_company: string;
  rating_per_company: string;
  signal_strength: "SIGNAL" | "LOW_SIGNAL" | "NO_SIGNAL";
}

export interface IRequest {
  params: {
    company_id?: Array<string>;
    company_name?: string;
    brief_id?: string;
    sector?: Array<string>;
    company_headquarters?: Array<string>;
    min_headcount?: number;
    max_headcount?: number;
    start_row?: number;
    end_row?: number;
    sort_field?: keyof Company;
    sort_direction?: "asc" | "desc";
    use_new_sectors?: "true" | "false";
    year?: string[];
  };
  headers: {
    token: `Bearer ${string}`;
  };
}

export interface IResponse {
  status: number;
  code: string;
  data: Company[];
}

interface CreateQueryConfig<T> {
  enabled?: boolean;
  params?: IRequest["params"];
  onSuccess?: (data: IResponse["data"]) => void;
  onError?: (error: Error) => void;
  onNotification?: CacheQueryNotification;
  transform?: (data: IResponse["data"]) => T;
  auth?: {
    accessToken?: string | null;
  };
  captureError?: ReturnType<typeof Error.hooks.useCaptureError>;
  notifications?: CacheQueryNotification;
}

export function createCompaniesQuery<T>(props: CreateQueryConfig<T>) {
  return {
    queryKey: [cacheKey, props.params],
    queryFn: async () => {
      const accessToken = props.auth?.accessToken;
      if (!accessToken) {
        props.captureError?.(
          new ClientError({
            name: "No access token found",
            message: "No access token found",
          }),
        );
        notification.error({
          message: "Error",
          data: {
            description: "Failed to fetch companies",
          },
        });
        return;
      }
      const response = await http.dataApi.get<IResponse>("/companies", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: props.params,
      });
      if (response.data.status !== 200) {
        throw new ClientError({
          name: "Client Error",
          message: `Failed to fetch metadata: ${response.data.code} status ${response.data.status}`,
        });
      }
      const transformed = props.transform ? props.transform(response.data.data) : null;
      return { response, transformed };
    },
    enabled: props.enabled,
    meta: {
      notification: {
        disable: false,
        onError: {
          message: "Error",
          data: {
            description: "Failed to fetch companies",
          },
        },
      },
      ...props.onNotification,
    },
  };
}
