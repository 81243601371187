import { useQueries } from "@tanstack/react-query";
import { Auth } from "@/core/auth";
import { Error } from "@/core/errors";
import { useEffect } from "react";
import { StateLayer } from "../state-layer";
import { Company } from "../../api/companies";
import { createCompaniesQuery } from "../../api/companies";
import { useSearch } from "@tanstack/react-router";
import {
  HeadcountTransformer,
  NSSTransformer,
  PrevalenceTransformer,
  RatingTransformer,
  ReviewCountTransformer,
} from "../../transformers";
import { ProcessedCompany } from "../types";

export function useQueryMetadata() {
  const auth = Auth.Machine.useState();
  const captureError = Error.hooks.useCaptureError();
  const params = useSearch({ from: "/report" });
  const state = StateLayer.useState();

  const result = useQueries({
    queries: [
      createCompaniesQuery({
        enabled: !!auth?.readonly.accessToken && params.company_id.length > 0,
        auth: {
          accessToken: auth?.readonly.accessToken,
        },
        params: {
          company_id: params.company_id,
          use_new_sectors: "true",
        },
        transform: (data) => {
          const companies: Record<string, ProcessedCompany> = {};

          const nssTransformer = new NSSTransformer();
          const prevalenceTransformer = new PrevalenceTransformer();
          const countTransformer = new ReviewCountTransformer();
          const ratingTransformer = new RatingTransformer();
          const headcountTransformer = new HeadcountTransformer();

          data.forEach((company) => {
            companies[company.company_id] = {
              ...company,
              nss_per_company: {
                percentage: nssTransformer.toPercentage(company.nss_per_company),
                strength: nssTransformer.toStrength(company.nss_per_company),
                sentiment: nssTransformer.toSentiment(company.nss_per_company),
                percentage_string: nssTransformer.toPercentageString(company.nss_per_company),
              },
              prevalence_per_company: {
                percentage: prevalenceTransformer.toPercentage(company.prevalence_per_company),
                percentage_string: prevalenceTransformer.toPercentageString(company.prevalence_per_company),
              },
              count_per_company: {
                count: countTransformer.toNumber(company.count_per_company),
                compact_number: countTransformer.toCompactNumber(company.count_per_company),
                strength: countTransformer.toStrength(company.signal_strength),
              },
              rating_per_company: {
                rating: ratingTransformer.toNumber(company.rating_per_company),
              },
              sectors: {
                primary: company.sectors.filter((sector) => sector.sector_is_primary),
                secondary: company.sectors.filter((sector) => !sector.sector_is_primary),
              },
              company_headcount: {
                compact_number: headcountTransformer.toCompactNumber(company.company_headcount?.toString()),
              },
            };
          });

          return companies;
        },
        captureError,
      }),
    ],
  });

  useEffect(() => {
    state.mutable.state.metadata.company.pending = result[0].isPending;
    state.mutable.state.metadata.company.error = result[0].isError;
    state.mutable.state.metadata.company.data = result[0].data?.transformed ?? {};
  }, [result[0].data?.transformed, result[0].isPending, result[0].isError]);
}
