// Rating Transformer
export class RatingTransformer {
  toNumber(value: string): number | null {
    let number = Number(value);
    if (isNaN(number)) {
      return null;
    }
    number = Number(number.toFixed(1));
    return number;
  }
}

// Prevalence Transformer
export class PrevalenceTransformer {
  toPercentage(value: string): number | null {
    let number = Number(value) * 100;
    if (isNaN(number)) {
      return null;
    }
    number = Number(number.toFixed(1));
    return number;
  }
  toPercentageString(value: string): string | null {
    const number = this.toPercentage(value);
    if (number === null) {
      return null;
    }
    return `${number}%`;
  }
}

// NSS Transformer
export class NSSTransformer {
  toPercentage(value: string): number | null {
    let number = Number(value) * 100;
    if (isNaN(number)) {
      return null;
    }
    number = Number(number.toFixed(1));
    return number;
  }
  toPercentageString(value: string): string | null {
    const number = this.toPercentage(value);
    if (number === null) {
      return null;
    }
    return `${number}%`;
  }
  toStrength(value: string): "high" | "medium" | "low" | "neutral" | null {
    const number = Number(value);
    if (isNaN(number)) {
      return null;
    }
    if (number >= 0.7) {
      return "high";
    } else if (number > 0.2) {
      return "medium";
    } else if (number >= -0.2) {
      return "neutral";
    } else if (number >= -0.3) {
      return "low";
    } else {
      return "low";
    }
  }
  toSentiment(value: string): "pos" | "neg" | "neutral" | null {
    const number = Number(value);
    if (isNaN(number)) {
      return null;
    }
    if (number >= 0.2) {
      return "pos";
    }
    if (number <= -0.2) {
      return "neg";
    }
    return "neutral";
  }
}

// Headcount Transformer
export class HeadcountTransformer {
  toNumber(value: string): number | null {
    let number = Number(value);
    if (isNaN(number)) {
      return null;
    }
    number = Number(number.toFixed(1));
    return number;
  }
  toCompactNumber(value: string): string | null {
    let number = Number(value);

    if (isNaN(number)) {
      return null;
    }
    // Using Math.trunc to convert to an integer, effectively replacing _.toInteger
    number = Math.trunc(number);

    if (number < 1000) {
      return String(number);
    } else if (number >= 1000 && number < 1_000_000) {
      return (number / 1000).toFixed(1) + "K";
    } else if (number >= 1_000_000 && number < 1_000_000_000) {
      return (number / 1_000_000).toFixed(1) + "M";
    } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
      return (number / 1_000_000_000).toFixed(1) + "B";
    } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
      return (number / 1_000_000_000_000).toFixed(1) + "T";
    }
    return null;
  }
}

export class ReviewCountTransformer {
  toNumber(value: string): number | null {
    let number = Number(value);
    if (isNaN(number)) {
      return null;
    }
    number = Number(number.toFixed(1));
    return number;
  }
  toCompactNumber(value: string): string | null {
    let number = Number(value);

    if (isNaN(number)) {
      return null;
    }
    // Using Math.trunc to convert to an integer, effectively replacing _.toInteger
    number = Math.trunc(number);

    if (number < 1000) {
      return String(number);
    } else if (number >= 1000 && number < 1_000_000) {
      return (number / 1000).toFixed(1) + "K";
    } else if (number >= 1_000_000 && number < 1_000_000_000) {
      return (number / 1_000_000).toFixed(1) + "M";
    } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
      return (number / 1_000_000_000).toFixed(1) + "B";
    } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
      return (number / 1_000_000_000_000).toFixed(1) + "T";
    }
    return "-";
  }

  toStrength(signalStrength: "LOW_SIGNAL" | "SIGNAL" | "NO_SIGNAL"): "low" | "ok" | null {
    return signalStrength === "LOW_SIGNAL" ? "low" : signalStrength === "SIGNAL" ? "ok" : null;
  }
}
