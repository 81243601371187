/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import r, { useRef } from "react";
import * as v from "valtio";

/**
 * Binds all methods of a class instance to the instance itself.
 * This function is used to preserve the correct `this` context when using
 * class-based state management with Valtio proxies.
 *
 * @template T
 * @param {T} instance - The class instance to bind methods to.
 * @returns {T} The same instance with all methods bound.
 *
 * @example
 * class Store {
 *   count = 0;
 *   increment() {
 *     this.count += 1;
 *   }
 * }
 *
 * const store = bind(proxy(new Store()));
 * const snap = useSnapshot(store);
 * snap.increment(); // This will work correctly
 *
 * @see {@link https://github.com/pmndrs/valtio/discussions/466|Valtio Discussion}
 * @see {@link https://github.com/pmndrs/valtio/issues/205#issuecomment-891481107|Valtio Issue Comment}
 */
export function bind<T extends object>(instance: T): T {
  const obj = instance as any;
  const names = Object.getOwnPropertyNames(Object.getPrototypeOf(obj));

  for (const name of names) {
    const method = obj[name];
    if (name === "constructor" || typeof method !== "function") continue;
    obj[name] = (...args: unknown[]) => method.apply(instance, args);
  }

  return instance;
}

/**
 * Creates a reactive proxy object with bound methods.
 *
 * This function combines Valtio's `proxy` for reactivity and a custom `bind` function
 * to ensure all methods maintain the correct `this` context when called.
 *
 * @template T
 * @param {T} initialState - An object (typically a class instance) to be made reactive.
 * @returns {T} A reactive proxy of the initial state with all its methods bound.
 *
 * @example
 * class Counter {
 *   value = 0;
 *   increment() {
 *     this.value++;
 *   }
 * }
 *
 * const reactiveCounter = boundProxy(new Counter());
 *
 * // The following will update the state and trigger re-renders in React components:
 * reactiveCounter.increment();
 * console.log(reactiveCounter.value); // 1
 *
 * // Methods can be passed around without losing context:
 * const incrementFn = reactiveCounter.increment;
 * incrementFn(); // This still works correctly
 */
export function boundProxy<T extends object>(initialState: T): T {
  return bind(v.proxy(initialState));
}

interface Options<T extends object> {
  initialState?: T;
  subscriber?: (StateMachine: T) => void;
}

/**
 * Creates a Valtio context provider with associated hooks for state management.
 *
 * This function sets up a React context with a Valtio proxy state, providing a way to
 * manage and access reactive state throughout a component tree. It includes an optional
 * subscriber that can be used to react to state changes.
 *
 * The subscriber is a function that gets called whenever the state
 * changes. This can be useful for:
 * 1. Syncing state with external storage (e.g., localStorage or a backend API)
 * 2. Triggering side effects based on state changes (e.g., analytics tracking)
 *
 * @template T - The type of the state object
 * @param {string} name - The name of the context, used for error messages and debugging
 * @param {T} initialState - The initial state object
 * @param {Options<T>} [options] - Optional configuration
 * @param {(state: T) => void} [options.onStateChange] - Optional callback triggered on state changes
 * @returns {{ Provider: React.FC<{ children: ReactNode }>, useValtioContext: () => T, useValtioSnapshot: () => T }}
 *
 * @example
 * // Creating a provider for a user state
 * const { Provider, useValtioContext, useValtioSnapshot } = createValtioContextProvider('UserContext', { name: '', age: 0 }, {
 *   onStateChange: (state) => {
 *     // Sync user state with localStorage on every change
 *     localStorage.setItem('user', JSON.stringify(state));
 *   }
 * });
 *
 * @example
 * // Using the provider and hooks in components
 * const App = () => (
 *   <Provider>
 *     <UserProfile />
 *   </Provider>
 * );
 *
 * const UserProfile = () => {
 *   const userState = useValtioContext();
 *   const userSnap = useValtioSnapshot();
 *   // Use userState for updates, userSnap for reading in render
 *   return <div>{userSnap.name}</div>;
 * };
 */
export function createValtioContextProvider<T extends object>(name: string, options?: Options<T>) {
  const Context = r.createContext<T | null>(null);
  Context.displayName = name;

  const Injector = (props: { children: React.ReactNode; value?: T; subscriber?: (StateMachine: T) => void }) => {
    const initialState = options?.initialState;
    const value = props.value;
    if (value === undefined && initialState === undefined) {
      throw new Error(`Either 'value' prop or 'initialState' option must be provided for ${name} context.`);
    }
    // We use useMemo here for stability:
    // We want the 'state' object to remain the same between renders unless its inputs change.
    // This helps prevent unnecessary re-renders in child components that depend on this state.
    // The state only updates when 'value' or 'initialState' change, ensuring consistent behavior.
    const state = r.useMemo(() => boundProxy(value ?? (initialState as T)), [value, initialState]);
    // const state = useRef(boundProxy(value ?? (initialState as T))).current

    r.useEffect(() => {
      const subscriber = props.subscriber ?? options?.subscriber;
      if (subscriber === undefined) return;
      const unsubscribe = v.subscribe(state, () => {
        subscriber?.(state);
      });
      return () => {
        unsubscribe();
      };
    }, [value, initialState]);

    return <Context.Provider value={state}>{props.children}</Context.Provider>;
  };

  const useContext = () => {
    const context = r.useContext(Context);
    if (context === null) {
      throw new Error(`${name} must be used within its provider`);
    }
    return context;
  };

  const useSnapshot = () => {
    const state = useContext();
    return v.useSnapshot(state);
  };

  const useState = () => {
    const mutable = useContext();
    const readonly = v.useSnapshot(mutable);
    return { readonly, mutable };
  };
  /**
   * Registers certain state properties as reactive dependencies,
   * even if they're not directly used in the component's render.
   *
   * This function is useful when you want a component to react to changes
   * in specific state properties without explicitly reading them in the
   * component body.
   *
   * @param {...any} args - The state properties to be registered as dependencies.
   *
   * @example
   * const { count, name } = useSnapshot(state);
   * registerReactiveDependencies(count, name);
   *
   * // Now the component will re-render when either count or name changes,
   * // even if they're not directly used in the JSX.
   */
  function forceRegisterReactiveDependencies(...args: any[]) {
    const log = false;
    // The empty function body tricks Valtio into thinking these props are being used,
    // thus making them reactive without actually using them in the component logic.
    if (log) {
      console.info("forceRegisterReactiveDependencies", args);
    }
  }

  return { Injector, useSnapshot, useContext, useState, forceRegisterReactiveDependencies };
}

export function createRenderStableProxyContextInjector<T extends object>(name?: string) {
  const Context = r.createContext<T | null>(null);
  Context.displayName = name;

  const Injector = (props: { children: React.ReactNode; value?: T; name?: string }) => {
    const value = props.value;
    if (value === undefined) {
      throw new Error(`Either 'value' prop or 'initialState' option must be provided for ${name} context.`);
    }

    const state = useRef(v.proxy(value)).current;

    return <Context.Provider value={state}>{props.children}</Context.Provider>;
  };

  const useContext = () => {
    const context = r.useContext(Context);
    if (context === null) {
      throw new Error(`${name} must be used within its provider`);
    }
    return context;
  };

  const useState = () => {
    const mutable = useContext();
    const readonly = v.useSnapshot(mutable);
    return { readonly, mutable };
  };

  return { Injector, useState };
}
