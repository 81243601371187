import { Outlet, createRootRouteWithContext, ParsedLocation } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { Error500Component } from "@/core/errors/components/Error500Component";
import { Error404Component } from "@/core/errors/components/Error404Component";
import { Error406Component } from "@/core/errors/components/Error406Component";
import { Error } from "@/core/errors";
import { AuthInjector, AuthSecureComponent } from "@/core/auth";
import { NotificationInjector } from "@/core/notifications";
import { QueryClientInjector } from "@/core/cache";
import { AppSidebar } from "@/features/app-sidebar/AppSidebar";
import { ThemeProvider } from "@/core/components/third-party/theme-provider";
const SecuredComponent = AuthSecureComponent(() => <Outlet />);

interface RootContext {
  breadcrumbs: Map<string, { displayName: string; location: ParsedLocation }>;
}

export const Route = createRootRouteWithContext<RootContext>()({
  component: RootComponent,
  errorComponent: Error500Component,
  notFoundComponent: Error404Component,
});

function RootComponent() {
  return (
    <AuthInjector>
      <NotificationInjector />
      <QueryClientInjector>
        <Error.Injector>
          <Error406Component>
            <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
              <AppSidebar>
                <SecuredComponent />
              </AppSidebar>
              <TanStackRouterDevtools position="top-right" />
            </ThemeProvider>
          </Error406Component>
        </Error.Injector>
      </QueryClientInjector>
    </AuthInjector>
  );
}
